import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import arrow2 from './arrow2.gif';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    [theme.breakpoints.up('xl')]: {
      width: 100,
    },
    [theme.breakpoints.down('xl')]: {
      width: 90,
    },
    [theme.breakpoints.down('lg')]: {
      width: 80,
    },
    [theme.breakpoints.down('md')]: {
      width: 70,
    },
    [theme.breakpoints.down('sm')]: {
      width: 60,
    },
    [theme.breakpoints.down('xs')]: {
      width: 50,
    },
    zIndex: 5000,
    bottom: 0,
    opacity: 0.5,
  },
  image: {
    margin: 'auto',
    width: '100%',
  }
}));

const Arrow = props => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <img src={arrow2} className={classes.image} alt={props.alt || 'más juegos'} title={props.alt || 'más juegos'} />
    </Box>
  )
}

export default Arrow;
