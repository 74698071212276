import React from 'react';
import Box from "@material-ui/core/Box";

const DataFiscal = props => {
  const data = {
    url: 'http://qr.afip.gob.ar/?qr=6GBwpf0-uqdJDtk8nvSiyw,,',
    position: 'center',
    width: 70,
    target: '_F960AFIPInfo',
    img: 'https://www.afip.gob.ar/images/f960/DATAWEB.jpg',
  };

  return (
    <React.Fragment>
      <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: 20 }}>
        <a href={data.url} target={data.target}><img alt="Data fiscal" width="70" height="96" loading="lazy" src={data.img} border="0" style={{margin: 'auto', width: data.width }}/></a>  
      </Box>
    </React.Fragment>
  )
}

export default DataFiscal;