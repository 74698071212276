const errorHandler = xhr => (url, callback) => event => {
  {
    console.log('error GET ', url);
    callback(event);
  }
}

const completed = xhr => (url, success, failed) => event => {
  if (xhr.status === 200) {
    const json = xhr.response;

    success(json);
  } else {
    console.log('error GET ', url);
    failed(event);
  }
}
const getJson = url => {
  return new Promise((resolve, reject) => {
    const xhr = new window.XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = 'json';
    xhr.withCredentials = false;
    xhr.send();
    xhr.onerror = errorHandler(xhr)(url, reject);
    xhr.onloadend = completed(xhr)(url, resolve, reject);
  });
}

export default getJson;