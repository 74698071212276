import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AlertIcon from './Icons/Alert';
import NewBoxIcon from './Icons/NewBox';
import NewRocketIcon from './Icons/NewRocket';
import NotificationIcon from './Icons/Notification';
import { facebookEvent } from '../../hooks/useFacebookEvent';
import useGamesForSaleState from '../../hooks/useGamesForSaleState';
import CircularProgress from '@material-ui/core/CircularProgress';

export const bgColor = (theme, transparencyHex = '11') => ({
  forPlay: '#00000000',
  forSale: `${theme.palette.primary.main}${transparencyHex}`,
  notAvailable: `${theme.palette.grey[800]}${transparencyHex}`,
  preorder: `${theme.palette.secondary.main}${transparencyHex}`,
  commingSoon: `${theme.palette.highlight.main}${transparencyHex}`,
})

const useStyles = type => makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: -16,
  },
  paper: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: 'auto',
    backgroundColor: bgColor(theme)[type || 'forPlay'],
    color: theme.palette.text.primary,
    marginLeft: - (theme.spacing(2) * 2),
    marginRight: - (theme.spacing(2) * 2),
    clear: 'right',
  },
  cells: {
    margin: 'auto',
    display: 'flex',
  },
  price: {
    fontWeight: 500,
  },
  boxIcon: {
    fontSize: 48,
    float: 'right',
    marginRight: 8,
    top: -26,
    marginBottom: -24,
    [theme.breakpoints.up('sm')]: {
      marginRight: 8,
      marginBottom: -16,
    },
    [theme.breakpoints.up('md')]: {
      marginRight: 10,
      marginBottom: -16,
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: 10,
      marginBottom: -10,
    },
    [theme.breakpoints.up('xl')]: {
      marginRight: 10,
      marginBottom: -10,
    },
  },
  rocketIcon: {
    fontSize: 48,
    float: 'right',
    marginRight: '14px',
    top: -26,
    marginBottom: -24,
    [theme.breakpoints.up('sm')]: {
      marginBottom: -16,
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: -16,
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: -10,
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: -10,
    },
  },
  newBoxIcon: {
    fontSize: 48,
    float: 'right',
    top: -26,
    marginRight: 8,
    marginBottom: -24,
    [theme.breakpoints.up('sm')]: {
      marginRight: 10,
    },
    [theme.breakpoints.up('md')]: {
      marginRight: 16,
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: 16,
    },
    [theme.breakpoints.up('xl')]: {
      marginRight: 16,
    },
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

const whatsAppLink = 'https://api.whatsapp.com/send?phone=541165199287&text='

export const facebookData = (sellData) => {
  if (sellData.isForSale === false) return {};
  const { game } = sellData;
  return ({
    content_ids: [game.game_for_sale[0].contentful_id],
    content_type: 'product'
  })
}

const TrackableButton = (props) => {
  const { sellData, link, intent, ...passProps } = props;

  const trackClick = () => {
    event.preventDefault()
    facebookEvent('track', 'AddToCart', facebookData(sellData));
    const data = window.dataLayer;

    if (data) {
      const extra = {
        currency: "ARS",
        value: sellData.saleInfo.shopPrice,
      }
      const item = {
        item_id: sellData.id,
        item_name: sellData.game.title,
        price: sellData.saleInfo.shopPrice,
        quantity: 1,
      }
      const eventData = {
        ecommerce: {
          ...extra,
          items: [item],  
        }
      }
      switch (intent) {
        case 'order':
          data.push({ event: "add_to_cart", ...eventData })
          break;
        case 'preorder':
          data.push({ event: "add_to_cart", ...eventData })
          break;
        case 'notification':
          data.push({ event: "add_to_wishlist", ...eventData })
          break;
      }
    }
    if (window) {
      const win = window.open(link, '_blank');
      win.focus();
    }
  };

  return (
    <Button {...passProps} variant="contained" onClick={() => trackClick()}>
      {props.children}
    </Button>
  )
}


const Price = (props) => {
  const { game, sellData } = props;
  const classes = useStyles('forSale')();
  const saleInfo = game.game_for_sale[0];
  return (
    <React.Fragment>
      <Typography variant="h4" className={classes.price}>
        $ {sellData.saleInfo.shopPrice}
      </Typography>
    </React.Fragment>
  )
}

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
      backgroundColor: purple[700],
    },
  },
}))(TrackableButton);

const buyLink = props => {
  const { sellData } = props;
  return `${whatsAppLink}Me interesa comprar ${sellData.game.title} id:${sellData.game.contentful_id}`;
}

const preorderLink = props => {
  const { sellData } = props;
  return `${whatsAppLink}Me interesa la pre-venta de ${sellData.game.title} id:${sellData.game.contentful_id}`;
}

const notifyMeLink = props => {
  const { sellData } = props;
  return `${whatsAppLink}Me interesa que me avisen cuando esté disponible para comprar ${sellData.game.title} id:${sellData.game.contentful_id}`;
}

const SellButton = (props) => {
  return (
    <TrackableButton {...props} link={buyLink(props)} color="primary" variant="contained" intent="order">comprar</TrackableButton>
  )
}

const PreorderButton = (props) => {
  return (
    <TrackableButton {...props} link={preorderLink(props)} color="secondary" variant="contained" intent="preorder">pre compra</TrackableButton>
  )
}

const NotifyButton = (props) => {
  return (
    <ColorButton {...props} variant="contained" link={notifyMeLink(props)} intent="notification">Avisarme</ColorButton>
  )
}

const Availability = ({saleInfo}) => {
  let inStockText
  if (saleInfo.inStock) {
    inStockText = 'en stock';
  } else if (!saleInfo.inStock && saleInfo.preorderAvailable) {
    inStockText = 'preventa disponible';
  } else {
    inStockText = 'no disponible';
  }
  return (
    <Typography>{inStockText}</Typography>
  )
}

const presentDate = (dateString) => {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return `${date.toLocaleDateString('es-AR', options)}`
}

export const detectType = live => {
  if (!live) return 'forPlay';
  if (live.inStock) return 'forSale';
  if (!live.inStock && live.preorderAvailable) return 'preorder';
  return 'notAvailable';
}

export const isForSale = game => {
  if (!game.game_for_sale) return false;
  if (!game.game_for_sale.length) return false;
  if (game.game_for_sale.length === 0) return false;
  return true
}

export const presentSellInfo = (game, gameForSaleInfo) => {
  if (!isForSale(game)) return {
    isForSale: false,
  };
  const metadata = game.game_for_sale[0];
  const liveRecord = gameForSaleInfo && gameForSaleInfo(metadata.id);
  if (!liveRecord) {
    return {
      isForSale: false,
    }
  }
  const live = liveRecord.data;
  const type = detectType(live);
  const saleInfo = live;
  const inStock = (saleInfo.inStock === true);
  const preorder = (saleInfo.preorderAvailable === true);
  const notAvailable = (!inStock && !preorder); 
  const lastUnit = parseInt(saleInfo.units, 10) === 1;
  const lastUnitText = 'última unidad';
  const language = metadata.language || 'español';
  const data = {
    type,
    isForSale: true,
    inStock,
    preorder,
    notAvailable,
    lastUnit,
    lastUnitText,
    language,
    saleInfo,
    game,
    id: metadata.id,
  }
  return data;
}

const SellBadge = (props) => {
  const [sellData, setSellData] = useState();
  const [jsonLdTag, setJsonLdTag] = useState();
  const { game, firebase } = props;
  const gameForSale = isForSale(game);
  const { gamesForSale: liveGames, gameForSaleInfo, liveInfoLoaded, jsonLd } = useGamesForSaleState();
  useEffect(() => {
    if (gameForSale && liveInfoLoaded && gameForSaleInfo) {
      const data = presentSellInfo(game, gameForSaleInfo);
      setSellData(data);
      facebookEvent('ViewContent', facebookData(data), (data.isForSale === true));
    }
  }, [liveInfoLoaded, gameForSale, gameForSaleInfo, game ])
  useEffect(() => {
    if (gameForSale && liveInfoLoaded) {
      const jsonData = JSON.stringify(jsonLd(game.game_for_sale[0].contentful_id, game), null, 2);
      setJsonLdTag(jsonData);
    }
  }, [liveInfoLoaded, gameForSale, game, jsonLd]);
  useEffect(() => {
    if (gameForSale && jsonLdTag) {
      const jsonScriptTag = document.createElement("script");
      jsonScriptTag.type = 'application/ld+json';
      jsonScriptTag.text = jsonLdTag;
      document.body.appendChild(jsonScriptTag);
    }
  },[jsonLdTag, gameForSale])

  const defaultType = (sellData && sellData.type) ? sellData.type : 'forPlay';
  const classes = useStyles(defaultType)();

  if (!game.game_for_sale) return null;

  return (
    <React.Fragment>
    {sellData && <div className={classes.root}>
      {sellData.inStock && <NewBoxIcon className={classes.newBoxIcon} />}
      {sellData.preorder && <NewRocketIcon className={classes.rocketIcon} />}
      {sellData.notAvailable && <NotificationIcon className={classes.boxIcon} />}
      <Box className={classes.paper}>

        <Grid container spacing={0} direction="row">
          <Grid item xs={4} className={classes.cells}>
            <Grid
              container
              direction="column"
              spacing={1}
              className={classes.cells}
              
             >
              <Availability saleInfo={sellData.saleInfo} />
              <Price game={game} sellData={sellData} />
              <Typography variant="body2" color="textSecondary">
                idioma <Typography variant="body2" style={{fontWeight: 600}} component="span">{sellData.language}</Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid 
            item xs={4}
            className={classes.cells}
          >
          <Grid container spacing={0} direction="row">
            </Grid>
          </Grid>
          <Grid
            item
            className={classes.cells}
          >
            <Grid container spacing={1} direction="column" justify="center" alignContent="center" alignItems="center">
              <Grid item>
              </Grid>
              {sellData.inStock && <Grid item className={classes.cells}>
                <SellButton sellData={sellData} firebase={firebase} />
              </Grid>}
              {sellData.preorder && <Grid item className={classes.cells}>
                <PreorderButton sellData={sellData} firebase={firebase} />
              </Grid>}
              {sellData.notAvailable && <Grid item className={classes.cells}>
                <NotifyButton sellData={sellData} firebase={firebase} />
              </Grid>}
              <Grid item className={classes.cells}>
                {sellData.lastUnit &&
                <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                  <Grid item>
                    <AlertIcon />
                  </Grid>
                  <Grid item>
                    {sellData.lastUnitText}
                  </Grid>
                </Grid>}
                {sellData.preorder && <Grid container spacing={0} direction="column" justify="center" alignItems="center">
                    <Grid item>
                      disponible:
                    </Grid>
                    <Grid item>
                      pronto
                    </Grid>
                  </Grid>}
                </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>}
    {!liveInfoLoaded && <Box className={classes.loading}><CircularProgress /></Box>}

    </React.Fragment>

  )
}

export default SellBadge;