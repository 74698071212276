import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
// import { navigate } from '@reach/router';
import { navigate } from 'gatsby';
import { FirebaseUserContext, UserGamesContext } from '../GamesLayout';

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginLeft: theme.spacing(0.2),
    position: 'absolute',
    right: 0,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

export default function MenuBar(props) {
  const { search } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState({});
  const userGamesCtx = useContext(UserGamesContext)
  const userContext = useContext(FirebaseUserContext)

  const isAdmin = userContext && userContext.userRole && userContext.userRole === "admin"

  const handleClick = id => event => {
    setAnchorEl(existing => ({
      ...existing,
      [id]: event.currentTarget,
    }));
  };

  const navigateTo = (id, event, page) => {
    event.preventDefault();
    setAnchorEl( existing => ({
      ...existing,
      [id]: null,
    }));
    navigate(page);
  }

  const userGames = (id, event, page, collection) => {
    event.preventDefault();
    if (search && search.setCollection) {
      search.setCollection(collection)
      search.setNeedsSearch(true)
      search.setSearch(null)
      if (collection) {
        userGamesCtx.setUserGames(null)
      }
    }
    setAnchorEl( existing => ({
      ...existing,
      [id]: null,
    }));
    navigate(page);
  }


  const handleClose = id => () => {
    setAnchorEl(existing => ({
      ...existing,
      [id]: null,
    }));
  };

  return (
    <React.Fragment>
      {/* <div> */}
      <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" aria-controls="root-menu" aria-haspopup="true" onClick={handleClick('menu-0')}>
        {userGamesCtx && userGamesCtx.user && <Avatar src={userGamesCtx.user.photoURL} className={classes.small}/>}
        {(!userGamesCtx || !userGamesCtx.user) && <MoreVertIcon />}
        
      </IconButton>
      <Menu
        id="root-menu"
        anchorEl={anchorEl['menu-0']}
        keepMounted
        open={Boolean(anchorEl['menu-0'])}
        onClose={handleClose('menu-0')}
        TransitionComponent={Fade}
      >
        <MenuItem selected={(props.menu === 'app/u?')} onClick={(event) => navigateTo('menu-0', event, "/app/u?", "app")}>Inicio</MenuItem>
        <MenuItem selected={(props.menu === 'app/u/coleccion?')} onClick={(event) => navigateTo('menu-0', event, "/app/u/coleccion?", "collection")}>Mi Colección</MenuItem>
        <MenuItem selected={(props.menu === 'app/u/profile?')} onClick={(event) => navigateTo('menu-0', event, "/app/u/profile?", "profile")}>Mi usuario</MenuItem>
        <MenuItem selected={(props.menu === 'ba/juegos')} onClick={(event) => userGames('menu-0', event, "/ba/juegos", null)}>Todos los Juegos</MenuItem>
        <MenuItem selected={(props.menu === 'ba/juegos?collection=loved')} onClick={(event) => userGames('menu-0', event, "/ba/juegos?collection=loved", "loved")}>Mis Favoritos</MenuItem>
        <MenuItem selected={(props.menu === 'ba/juegos?collection=played')} onClick={(event) => userGames('menu-0', event, "/ba/juegos?collection=played", "played")}>Los que jugué</MenuItem>
        <MenuItem selected={(props.menu === 'ba/juegos?collection=wantToPlay')} onClick={(event) => userGames('menu-0', event, "/ba/juegos?collection=wantToPlay", "wantToPlay")}>Quiero jugar</MenuItem>
        {isAdmin && <MenuItem selected={(props.menu === 'app/admin')} onClick={(event) => navigateTo('menu-0', event, "/app/admin", "app")}>Admin</MenuItem>}
        {isAdmin && <MenuItem selected={(props.menu === 'app/u/listas')} onClick={(event) => navigateTo('menu-0', event, "/app/u/listas", "app")}>Mis Listas</MenuItem>}
        
      </Menu>


      {/* </div> */}
    </React.Fragment>
  );
}