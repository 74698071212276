import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { navigate } from "gatsby";

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
}));

export default function MenuBar(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState({});
  const handleClick = id => event => {
    setAnchorEl(existing => ({
      ...existing,
      [id]: event.currentTarget,
    }));
  };

  const navigateTo = (id, event, page) => {
    event.preventDefault();
    setAnchorEl( existing => ({
      ...existing,
      [id]: null,
    }));
    navigate(page);
  }

  const handleClose = id => () => {
    setAnchorEl(existing => ({
      ...existing,
      [id]: null,
    }));
  };

  return (
    <React.Fragment>
      <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" aria-controls="root-menu" aria-haspopup="true" onClick={handleClick('menu-0')}>
        <MenuIcon />
      </IconButton>
      <Menu
        id="root-menu"
        anchorEl={anchorEl['menu-0']}
        keepMounted
        open={Boolean(anchorEl['menu-0'])}
        onClose={handleClose('menu-0')}
        TransitionComponent={Fade}
      >
        <MenuItem selected={(props.menu === 'index')} onClick={(event) => navigateTo('menu-0', event, "/")}>Home</MenuItem>
        <MenuItem selected={(props.menu === '/shop/home')} onClick={(event) => navigateTo('menu-0',event, "/shop/home/")}>Shop</MenuItem>
        <MenuItem selected={(props.menu === '/shop/como-funciona')} onClick={(event) => navigateTo('menu-0',event, "/shop/como-funciona")}>Comprar juegos</MenuItem>
        <MenuItem selected={(props.menu === '/contenido/venir-a-jugar/')} onClick={(event) => navigateTo('menu-0',event, "/contenido/venir-a-jugar/")}>Venir a Jugar</MenuItem>
        <MenuItem selected={(props.menu === '/metro-card/')} onClick={(event) => navigateTo('menu-0',event, "/metro-card/")}>Beneficios</MenuItem>
        <MenuItem selected={(props.menu === '/ba/juegosEnVenta')} onClick={(event) => navigateTo('menu-0',event, "/ba/juegosEnVenta")}>Juegos en Venta</MenuItem>
        <MenuItem selected={(props.menu === '/ba/juegos')} onClick={(event) => navigateTo('menu-0',event, "/ba/juegos")}>Juegos disponibles para jugar</MenuItem>
        <MenuItem selected={(props.menu === '/ba/proximamente')} onClick={(event) => navigateTo('menu-0',event, "/ba/proximamente")}>Lo que llega para jugar</MenuItem>
        <MenuItem selected={(props.menu === '/ba/juegos-para-dos')} onClick={(event) => navigateTo('menu-0',event, "/ba/juegos-para-dos")}>Juegos para 2 personas</MenuItem>
        <MenuItem selected={(props.menu === '/ba/tags')} onClick={(event) => navigateTo('menu-0',event, "/ba/tags")}>Juegos por Etiquetas</MenuItem>
        <MenuItem selected={(props.menu === '/ba/autores')} onClick={(event) => navigateTo('menu-0',event, "/ba/autores")}>Juegos por Autores</MenuItem>
        <MenuItem selected={(props.uri === '/Acerca-de')} onClick={(event) => navigateTo('menu-0',event, "/contenido/Acerca-de")}>Acerca de Conexión</MenuItem>
        <MenuItem selected={(props.uri === '/contacto')} onClick={(event) => navigateTo('menu-0',event, "/contacto")}>Contactarnos</MenuItem>

      </Menu>

    </React.Fragment>
  );
}