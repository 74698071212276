import React, {useEffect, useState } from 'react'
import LocalPlayOutlinedIcon from '@material-ui/icons/LocalPlayOutlined';
import LocalActivityTwoToneIcon from '@material-ui/icons/LocalActivityTwoTone';
import IconButton from '@material-ui/core/IconButton';

export const WantToPlayIcon = props => {
  const { fontSize = 'default' } = props;
  return (
    <LocalActivityTwoToneIcon fontSize={fontSize} style={{ color: 'rgb(31, 163, 0)' }}  />
  )
}

export const WantToPlay = props => {
  const { add, wantToPlay } = props;
  return (
      <IconButton aria-label="quiero jugar" onClick={add}>
        {wantToPlay && <LocalActivityTwoToneIcon style={{ color: 'rgb(31, 163, 0)' }}  />}
        {!wantToPlay && <LocalPlayOutlinedIcon  />}
      </IconButton>
  )
}

const useWantToPlayGame = (firestore, user, userGames, gameId, isMounted = true) => {
  const [wantToPlayState, setWantToPlayState] = useState()
  const addWantToPlay = () => async(ev) => {
    ev.stopPropagation()
    ev.preventDefault()
    ev.persist()
    if (isMounted && firestore && firestore.firestoreLite) {
      await userGames.addGameToList(userGames.userGames.wantToPlay.listId, gameId)
      setWantToPlayState(!wantToPlayState)
    }
  }
  useEffect(() => {
    if (isMounted && wantToPlayState === undefined && userGames && userGames.userGames && userGames.userGames.wantToPlay) {
      const wantToPlay = userGames.gameInList(userGames.userGames.wantToPlay.games, gameId)
      setWantToPlayState(wantToPlay)
    }
  },[userGames, wantToPlayState, gameId, isMounted])
  
  return {
    wantToPlayState,
    setWantToPlayState,
    userGames,
    addWantToPlay,
  }
}

export default useWantToPlayGame;


