import * as React from "react"
import SvgIcon from '@material-ui/core/SvgIcon';

function SvgComponent(props) {
  return (
    <SvgIcon viewBox="0 0 480 480" style={{widht: (props.width || 64), height: (props.height || 64), filter: 'drop-shadow(2px 2px 4px #666)'}} {...props}>
      <path
        d="M339.746 147c3.2-3 3.2-8 .2-11.2-1.2-1.198-2.801-2-4.602-2.402l-46.399-6.796c-2.601-.403-4.8-2-6-4.403l-20.8-42c-2-4-6.598-5.597-10.598-3.597-1.602.796-2.8 2-3.602 3.597l-20.8 42c-1.2 2.403-3.399 4-6 4.403l-46.399 7c-4.402.597-7.402 4.597-6.601 9 .199 1.796 1 3.398 2.402 4.597L204.145 180c1.8 1.8 2.8 4.398 2.199 7l-8 46.2c-.797 4.402 2.203 8.402 6.402 9.198 1.8.204 3.399 0 5-.796l41.598-21.801c2.402-1.2 5-1.2 7.402 0l41.598 21.8c3.8 2 8.601.598 10.601-3.402.801-1.597 1-3.398.801-5l-8-46.199c-.402-2.602.399-5.2 2.2-7zm0 0"
        fill="#ffae2c"
      />
      <path
        d="M315.344 170.8l-9.2 9c-1.8 1.802-2.8 4.4-2.199 7l8 46.2c.801 4.398-2.199 8.398-6.398 9.2-1.8.198-3.402 0-5-.802l-41.8-21.796c-1.403-.602-2.802-1-4.2-.801v58L420.747 214zm0 0"
        fill="#a35d2e"
      />
      <path
        d="M251.344 219.602l-41.598 21.796c-3.8 2-8.601.602-10.601-3.398-.801-1.602-1-3.398-.801-5l8-46.2c.402-2.6-.399-5.198-2.2-7l-9.597-9.402-105.8 43.403 165.8 62.8v-58c-1.203.2-2.203.399-3.203 1zm0 0"
        fill="#a35d2e"
      />
      <path
        d="M503.945 278l-166.398 62.8-83-64.198 166.2-62.801zm0 0"
        fill="#c1814c"
      />
      <path
        d="M337.547 340.8l-83-64.198V507l166.2-62.8V309.397zm0 0"
        fill="#b26e3b"
      />
      <path
        d="M5.547 278l166.398 62.8 83-64.198L88.746 213.8zm0 0"
        fill="#c1814c"
      />
      <path
        d="M171.945 340.8l-83.199-31.402V444.2L254.946 507V276.602zm0 0"
        fill="#b26e3b"
      />
      <path
        d="M327.344 53c-8.797-1.602-15.797-8.602-17.399-17.398 0-.204-.199-.403-.398-.204-.203 0-.203.204-.203.204-1.598 8.796-8.598 15.796-17.399 17.398-.199 0-.398.2-.199.398 0 .204.2.204.2.204 8.8 1.597 15.8 8.597 17.398 17.398 0 .2.203.398.402.2.2 0 .2-.2.2-.2 1.6-8.8 8.6-15.8 17.398-17.398.203 0 .402-.204.203-.403l-.203-.199zm0 0"
        fill="#f75c64"
      />
      <path
        d="M166.145 93.2h-17.2c-2.8 0-5-2.2-5-5s2.2-5 5-5h17.2c2.8 0 5 2.198 5 5s-2.2 5-5 5zm0 0"
        fill="#6dcc6d"
      />
      <path
        d="M157.547 101.8c-2.8 0-5-2.198-5-5V79.603c0-2.801 2.2-5 5-5 2.797 0 5 2.199 5 5V96.8c0 2.8-2.203 5-5 5zm0 0"
        fill="#6dcc6d"
      />
      <path
        d="M415.145 148.8h-17.2c-2.8 0-5-2.198-5-5s2.2-5 5-5h17.2c2.8 0 5 2.2 5 5s-2.399 5-5 5zm0 0"
        fill="#60bae2"
      />
      <path
        d="M406.547 157.602c-2.8 0-5-2.204-5-5v-17.204c0-2.796 2.2-5 5-5 2.797 0 5 2.204 5 5v17.204c-.203 2.597-2.402 5-5 5zm0 0"
        fill="#60bae2"
      />
      <path
        d="M130.344 152.398c1.402 0 2.601.602 3.601 1.403.2.199.399.398.602.8.2.2.398.598.398.797.2.204.2.602.2 1 0 .403.199.602.199 1 0 .403 0 .602-.2 1 0 .403-.199.602-.199 1-.199.403-.199.602-.398.801-.203.2-.402.602-.602.801-1.398 1.398-3.601 1.8-5.398 1-.203-.2-.602-.2-.8-.398-.2-.204-.602-.403-.802-.602-.199-.2-.398-.398-.601-.8-.2-.2-.399-.598-.399-.802-.199-.199-.199-.597-.199-1v-1-1c0-.398.2-.597.2-1 .199-.398.199-.597.398-.796.203-.204.402-.602.601-.801.2-.2.399-.403.801-.602.2-.199.598-.398.8-.398.4-.2.599-.2 1-.2.2-.203.4-.203.798-.203zm0 0M443.547 103.2c1.398 0 2.598.6 3.598 1.402.199.199.402.398.601.796.2.204.399.602.399.801.199.2.199.602.199 1 0 .403.203.602.203 1 0 .403 0 .602-.203 1 0 .403-.2.602-.2 1-.199.403-.199.602-.398.801-.2.2-.402.602-.601.8-1.399 1.4-3.598 1.802-5.399 1-.2-.198-.601-.198-.8-.402-.2-.199-.602-.398-.801-.597s-.399-.403-.598-.801c-.203-.2-.402-.602-.402-.8-.2-.2-.2-.598-.2-1v-1-1c0-.4.2-.598.2-1 .199-.4.199-.598.402-.802.2-.199.398-.597.598-.796.199-.204.402-.403.8-.602.2-.2.602-.398.801-.398.399-.204.598-.204 1-.204.2 0 .598-.199.8-.199zm0 0M55.344 324.602c1.402 0 2.601.597 3.601 1.398.2.2.399.398.602.8.2.2.398.598.398.802.2.199.2.597.2 1 0 .398.199.597 0 1v1c0 .398-.2.597-.2 1-.199.398-.199.597-.398.796-.203.204-.402.602-.602.801-1.398 1.403-3.601 1.801-5.398 1-.203-.199-.602-.199-.8-.398-.2-.2-.602-.403-.802-.602-.199-.199-.398-.398-.601-.8-.2-.2-.399-.598-.399-.797-.199-.204-.199-.602-.199-1v-1-1c0-.403.2-.602.2-1 .199-.403.199-.602.398-.801.203-.2.402-.602.601-.801.2-.2.399-.398.801-.602.2-.199.598-.398.8-.398.4-.2.599-.2 1-.2.2-.198.599-.198.798-.198zm0 0"
        fill="#ffae2c"
      />
      <path
        d="M278.945 0c1.399 0 2.602.602 3.602 1.398.2.204.398.403.598.801.199.2.402.602.402.801.2.398.2.602.2 1s.198.602.198 1 0 .602-.199 1c0 .398-.2.602-.2 1-.202.398-.202.602-.401.8s-.399.598-.598.802c-1.402 1.398-3.602 1.796-5.402 1-.2-.204-.598-.204-.801-.403-.2-.199-.598-.398-.797-.597-.203-.204-.402-.403-.602-.801-.199-.2-.398-.602-.398-.801-.203-.2-.203-.602-.203-1V5 4c0-.398.203-.602.203-1 .2-.398.2-.602.398-.8.2-.2.399-.598.602-.802.2-.199.398-.398.797-.597.203-.2.601-.403.8-.403.403-.199.602-.199 1-.199.2-.199.403-.199.801-.199zm0 0"
        fill="#fcd770"
      />
      <g fill="#231f20">
        <path d="M254.547 281.602c-2.8 0-5-2.204-5-5v-58c0-2.602 2-4.801 4.598-5 2.402-.204 4.8.398 7 1.398l41.601 21.8c1.399.802 3.2.2 4-1.198.2-.602.399-1.204.2-1.801l-8-46.2c-.801-4.203.6-8.402 3.8-11.402l9.2-9c1.398-1.398 3.6-1.8 5.398-1l105.402 43c2.598 1 3.8 4 2.8 6.602a6.346 6.346 0 01-2.8 2.8l-166.402 62.797c-.598.204-1.2.204-1.797.204zm5-56v43.796l147.598-55.796-90.598-37-7 6.796c-.602.602-1 1.602-.8 2.602l8 46.2c1.198 7-3.602 13.8-10.602 15-2.801.402-5.598 0-8.2-1.2zm0 0" />
        <path d="M303.945 247.398c-2 0-4.199-.597-6-1.398l-41.601-22c-.797-.398-1.797-.398-2.797 0l-41.602 21.8c-6.398 3.4-14.199.802-17.398-5.402-1.402-2.597-1.8-5.398-1.203-8.199l8-46.199c.203-1-.2-2-.797-2.602l-33.602-32.796c-5.199-5-5.199-13.204-.199-18.204 2-2 4.598-3.398 7.399-3.796l46.402-6.801c1-.2 1.797-.801 2.2-1.602l20.8-42c3.2-6.398 11-9 17.2-5.8 2.597 1.203 4.597 3.402 5.8 5.8l20.797 42c.402.801 1.203 1.403 2.203 1.602l46.398 6.8c7 1 12 7.598 11 14.598-.398 2.801-1.8 5.403-3.8 7.403l-33.598 32.796c-.602.602-1 1.602-.8 2.602l8 46.2c1.198 7-3.602 13.8-10.602 15-.801 0-1.399.198-2.2.198zm-48.8-33.796c2 0 4.199.597 6 1.398l41.601 21.8c1.399.802 3.2.2 4-1.198.2-.602.399-1.204.2-1.801l-8-46.2c-.801-4.203.6-8.402 3.8-11.402l33.598-32.8c1.203-1.2 1.203-3 0-4.2-.399-.398-1-.8-1.598-.8l-46.402-6.797c-4.2-.602-7.797-3.204-9.797-7l-20.8-42c-.802-1.403-2.403-2-4-1.403-.602.2-1 .801-1.403 1.403l-20.797 42c-1.8 3.796-5.602 6.398-9.8 7l-46.403 6.796c-1.598.204-2.598 1.801-2.399 3.403 0 .597.399 1.199.801 1.597l33.598 32.801c3 3 4.402 7.2 3.8 11.403l-8 46.199c-.199 1.597.801 3 2.403 3.398.598.2 1.2 0 1.797-.199l41.601-21.8c2-1 4-1.598 6.2-1.598zm0 0" />
        <path d="M254.547 281.602c-.602 0-1.203-.204-1.8-.403L86.944 218.4c-2.601-1-3.8-3.797-3-6.399.399-1.2 1.399-2.398 2.801-2.8l105.598-43.4c1.8-.8 4-.402 5.402 1l9.598 9.4c3 3 4.402 7.198 3.8 11.402l-8 46.199c-.199 1.597.801 3 2.403 3.398.598.2 1.2 0 1.797-.199l41.601-21.8c1.602-.802 3.2-1.4 5-1.4 2.801-.198 5.2 1.802 5.399 4.598v58.403c.203 2.597-2.2 4.8-4.797 4.8zm-152.203-68l147 55.796V226.2l-37.2 19.602c-6.398 3.398-14.199.8-17.398-5.403-1.402-2.597-1.8-5.398-1.2-8.199l8-46.199c.2-1-.202-2-.8-2.602l-7.2-7zm0 0" />
        <path d="M337.547 345.8a4.914 4.914 0 01-3-1l-83.203-64.198c-2.2-1.602-2.598-4.801-1-7 .601-.801 1.402-1.204 2.203-1.602l166.398-62.8c1.602-.598 3.399-.4 4.801.8l83.2 64.2c2.199 1.6 2.6 4.8 1 7-.602.8-1.4 1.198-2.2 1.6l-166.402 62.802c-.598.199-1.2.199-1.797.199zm-73-67.6l73.797 57 155.402-58.598-73.8-57zm0 0" />
        <path d="M254.547 512c-2.8 0-5-2.2-5-5V276.602c0-2.801 2.2-5 5-5 1.2 0 2.2.398 3 1l81 62.398 80.598-30.398c2.601-1 5.402.398 6.402 3 .2.597.398 1.199.398 1.796V444.2c0 2-1.199 4-3.199 4.602l-166.402 62.8c-.598.2-1.2.399-1.797.399zm5-225.2v213l156.2-59v-124l-76.403 28.802c-1.598.597-3.399.398-4.797-.801zm0 0" />
        <path d="M171.945 345.8c-.601 0-1.199-.198-1.8-.402L3.746 282.602c-2.601-1-3.8-3.801-3-6.403.399-.8.8-1.597 1.598-2.199l83.203-64.2c1.398-1 3.2-1.402 4.797-.8l166.402 63c2.598 1 3.8 3.8 3 6.398-.402.801-.8 1.602-1.601 2.204l-83.2 64.199c-.8.597-2 1-3 1zM15.746 276.399L171.145 335l73.8-57-155.398-58.602zm0 0" />
        <path d="M254.945 512c-.601 0-1.199-.2-1.8-.398L86.746 448.8c-2-.801-3.2-2.602-3.2-4.602V309.4c0-2.797 2.2-5 5-5 .599 0 1.2.203 1.798.402l80.601 30.398 81-62.398c2.2-1.602 5.399-1.2 7 1 .602.8 1 2 1 3v230.398c0 2.602-2.199 4.801-5 4.801zM93.746 440.8l156.399 59v-213l-75.2 58c-1.398 1-3.199 1.4-4.8.802L93.746 316.8zm0 0" />
      </g>
    </SvgIcon>
  )
}

export default SvgComponent
