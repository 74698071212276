import * as React from "react"
import SvgIcon from '@material-ui/core/SvgIcon';

function SvgComponent(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 500 500" {...props} color="secondary" style={{widht: (props.width || 64), height: (props.height || 64), filter: 'drop-shadow(2px 2px 4px #666)'}} >
      <path
        d="M512 256c0 141.385-114.615 256-256 256S0 397.385 0 256 114.615 0 256 0c39.487 0 76.884 8.934 110.279 24.91h.01c47.449 22.685 86.821 59.559 112.619 105.127a265.154 265.154 0 013.208 5.831 252.555 252.555 0 014.096 8.035C502.732 177.758 512 215.792 512 256z"
        fill="#9bd069"
      />
      <path
        d="M482.113 135.869L255.998 256 366.276 24.912h.01c49.455 23.646 90.143 62.704 115.827 110.957z"
        fill="#bcdf95"
      />
      <path
        d="M255.995 395.791c-76.341 0-138.448-62.108-138.448-138.448 0-76.341 62.108-138.448 138.448-138.448s138.448 62.107 138.448 138.448c0 76.34-62.108 138.448-138.448 138.448zm0-261.223c-67.698 0-122.775 55.077-122.775 122.775s55.077 122.774 122.775 122.774 122.774-55.077 122.774-122.774-55.076-122.775-122.774-122.775z"
        fill="#528e61"
      />
      <path
        d="M255.995 333.097c-41.771 0-75.755-33.983-75.755-75.755s33.983-75.755 75.755-75.755 75.755 33.983 75.755 75.755-33.984 75.755-75.755 75.755zm0-135.835c-33.129 0-60.081 26.953-60.081 60.081s26.953 60.081 60.081 60.081 60.081-26.953 60.081-60.081-26.953-60.081-60.081-60.081z"
        fill="#528e61"
      />
      <circle cx={255.995} cy={188.084} fill="#bcddc8" r={28.866} />
      <circle cx={153.581} cy={333.414} fill="#bcddc8" r={28.866} />
      <path
        d="M255.995 458.484c-110.91 0-201.141-90.232-201.141-201.141S145.085 56.202 255.995 56.202s201.141 90.232 201.141 201.141-90.231 201.141-201.141 201.141zm0-386.609c-102.267 0-185.468 83.201-185.468 185.468s83.201 185.468 185.468 185.468 185.468-83.201 185.468-185.468S358.262 71.875 255.995 71.875z"
        fill="#528e61"
      />
      <path
        d="M486.212 143.903L259.668 264.265a7.858 7.858 0 01-3.668.92 7.847 7.847 0 01-6.928-4.169c-2.027-3.814-.575-8.558 3.25-10.595l226.586-120.383a251.44 251.44 0 017.304 13.865z"
        fill="#457a52"
      />
      <g fill="#bcddc8">
        <circle cx={449.3} cy={256.001} r={28.866} />
        <circle cx={106.461} cy={135.356} r={28.866} />
      </g>
    </SvgIcon>
  )
}

export default SvgComponent