import { useLayoutEffect, useRef } from 'react';
const isBrowser = () => typeof window !== 'undefined';

export const facebookEvent = (event = 'ViewContent', data, condition = true) => {
  if (isBrowser() && window.fbq && condition) {
    window.fbq('track', event, data);
  }
}

const useFacebookEvent = (event = 'ViewContent', data, condition) => {
  useLayoutEffect(() => {
    facebookEvent(event, data, condition);
  })
}

export default useFacebookEvent;
