import {useEffect, useState} from 'react';


const useFirebase = () => {
  const [fbase, setFbase] = useState()  

  useEffect(() => {
    const loadFirebase = async () => {
      const app = await import('../components/Firebase/app')
      return app.default
    }
    if (!fbase) {
      const firebase = loadFirebase()
      firebase.then(fb => {
        console.log('fb', fb)
        setFbase(fb)
      })
    }
  }, [fbase]);
  return fbase
}

export default useFirebase;

