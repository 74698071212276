import React from 'react';
import Grid from '@material-ui/core/Grid';
import Layout from '../components/Layout';
import GameGrid from '../components/GameGrid';

export const Spacer = () => {
  const NAVIGATION_HEIGHT = 32;
  return (
    <div style={{ height: NAVIGATION_HEIGHT, content: '' }} />
  )
};

const Page = (props) => {
  const city = props.pageContext.city;
  const games = props.pageContext.games;
  return (
    <Layout>
      <Spacer />
      <Grid container spacing={3}>
        <GameGrid games={games} city={city}/>
      </Grid>
    </Layout>
  )
}

export default Page;
