import React, { useEffect, useState, useCallback } from 'react';
import getJson from '../lib/network/getJson';

const jsonUrl = 'https://storage.googleapis.com/pamir-public-db/shop/games.json';

const oneDayFromNow = () => new Date(Date.now() + 1000 * 60 * 60 * 24);

const month = (date) => (date.getMonth() + 1).toString().padStart(2,'0');
const day = (date) => (date.getDate() + 1).toString().padStart(2,'0');

const formatDate = (date) => `${date.getFullYear()}-${month(date)}-${day(date)}`

const itemAvailability = (saleInfo) => {
  if (saleInfo.preorderAvailable) {
    return 'PreOrder';
  } else if (saleInfo.inStock) {
    if (saleInfo.units < 2) {
      // return 'https://schema.org/LimitedAvailability';
      return 'https://schema.org/InStock';
    }
    return 'https://schema.org/InStock';
  } else if (!saleInfo.inStock && !saleInfo.preorderAvailable) {
    return 'https://schema.org/OutOfStock';
  }
  return 'https://schema.org/SoldOut';
}

const DAY = 60 * 60 * 24;

export const updatedDaysAgo = (days) => (game) => {
  const deltaInSeconds = days * DAY * 1000;
  const updatedAt = game.data.updated_at._seconds * 1000;
  const now = Date.now();
  return now - updatedAt < deltaInSeconds;
}

const useGamesForSaleState = (siteUrl = 'https://www.conexionberlin.com') => {
  const [gamesForSale, setGamesForSale] = useState();
  const [liveInfoLoaded, setLiveInfoLoaded] = useState(false);
  const gameForSaleInfo = useCallback((id) => {
    if (liveInfoLoaded && gamesForSale) {
      const data = gamesForSale.find((gfs) => 
        (gfs.id === id)
      )
      return data;
    }
    return null;
  }, [liveInfoLoaded, gamesForSale]);
  const jsonLd = useCallback((id, game) => {
    const saleInfo = gameForSaleInfo(id);
    if (!saleInfo) return null;
    return ({
      "@context": "https://schema.org/",
      "@type": "Product",
      "productID": saleInfo.id,
      "name": game.title,
      "image": `${game.image.gatsbyImageData.images.fallback.src}`,
      "description": game.plainDescription,
      "isbn": saleInfo.data.internationalCode,
      "sku": saleInfo.id,
      "offers": [{
        "@type": "Offer",
        "url": `${siteUrl}/ba/juego/${game.gameId}/${game.slug}`,
        "priceCurrency": "ARS",
        "price": `${saleInfo.data.shopPrice}.00`,
        "priceValidUntil": saleInfo.data.priceValidUntil || formatDate(oneDayFromNow()),
        "itemCondition": "https://schema.org/NewCondition",
        "availability": itemAvailability(saleInfo.data),
        "seller": {
          "@type": "Organization",
          "name": "Conexión Berlin Juegos"
        }
      }],
      "additionalProperty": [{
        "@type": "PropertyValue",
        "propertyID": "google_product_type",
        "value": "Toys & Games > Games > Board Games"
      }]
    })
  }, [liveInfoLoaded, gamesForSale]);
  useEffect(() => {
    const fetchLiveData = async () => {
      const url = `${jsonUrl}?${Date.now()}`;
      return await getJson(url);
    };
    // const fetchLiveData = async () => {
    //   const options = {
    //     method: 'GET', // *GET, POST, PUT, DELETE, etc.
    //     credentials: 'same-origin',
    //     headers: {
    //       'Content-Type': 'application/json'
    //     },
    //   }
    //   try {
    //     const response = await window.fetch(`${jsonUrl}?${Date.now()}`, options);
    //     const json = await response.json();
    //     return json;
    //   } catch(err) {
    //     console.log('err', err);
    //     return null;
    //   }
    // }
    fetchLiveData().then(
      json => {
        setGamesForSale(json)
        setLiveInfoLoaded(true)
      }
    );
  }, []);
  return { gamesForSale, gameForSaleInfo, liveInfoLoaded, jsonLd, updatedDaysAgo }
}

export default useGamesForSaleState;