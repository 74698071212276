import React, { useState, useEffect } from 'react';
import Loading from '../Loading';

const ClientOnly = React.forwardRef(({children, withLoading = true, fallback}, ref) => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true)
  }, [])
  if (!hasMounted) { 
    if (withLoading === true) {
      return <Loading ref={ref} loading={!hasMounted} />
    } else if (fallback) {
      return fallback
    }
    return null;
  }
  return children
});

export default ClientOnly
