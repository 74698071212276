import { useState, useEffect } from 'react';

const useGetStreamToken = (f, userContext) => {
  const [streamToken, setStreamToken] = useState();

  useEffect(() => {
    const signOut = () => {
      setStreamToken(null)
    }
    const loadGetStream = async() => {
      const getStreamTokenCall = f.httpsCallable(f.functions, 'backend-getStreamTokenCall');
      getStreamTokenCall({}).then((result) => {
        setStreamToken(result.data.userToken)
        userContext.subscribeOnSignOut(signOut)
        userContext.subscribeOnAuthStateChange(signOut)
      }).catch((err) => {
        console.log('cannot get token')
      });
    }
    if (f && f.httpsCallable && userContext && userContext.user && userContext.user.uid && !streamToken && !userContext.signingOut) {
      loadGetStream()
    }

  }, [streamToken, f, userContext])
  return streamToken
}

export default useGetStreamToken;