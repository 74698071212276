import React from 'react';

import Button from "@material-ui/core/Button";
import GatsbyLink from "../gatsby-link";

const GatsbyLinkedButton = props => {
  const { to, href } = props;
  const component = (to || href) ? GatsbyLink : `button`;
  return <Button component={component} {...props} />;
}

export default GatsbyLinkedButton;