import {useEffect, useState, useCallback, useRef} from 'react';
import { navigate } from 'gatsby';

const useAnonAuth = (auth, f) => {
  const [loadingResult, setLoadingResult] = useState(true)
  const [links, setLinks] = useState()
  const [isAnon, setIsAnon] = useState(false)  
  const [signingOut, setSigningOut] = useState(false)
  const [userLevel, setUserLevel] = useState()
  const [userRole, setUserRole] = useState()
  const [user, setUser] = useState()
  const [profileAccess, setProfileAccess] = useState()
  const [verificationToken, setVerificationToken] = useState()
  const onSignOut = useRef(new Map())
  const onSignIn = useRef(new Map())
  const onAuthStateChange = useRef(new Map())
  const linkGoogle = useCallback(async (auth) => {
    if (links) {
      const provider = new links.GoogleAuthProvider();
      return links.linkWithRedirect(auth.currentUser, provider)
        .then((credential) => credential)
        .catch((error) => {
          console.log('linkGoogle error', error)
          links.signInWithCredential(error.credential)
        })
    }

  }, [links])
  const linkFacebook = useCallback(async (auth) => {
    if (links) {
      const provider = new links.FacebookAuthProvider();
      links.linkWithRedirect(auth.currentUser, provider)
    }

  }, [links])
  const levelClaims = useCallback((claims) => {
    if (claims && claims.level) {
      setUserLevel(claims.level)
    }
    if (claims && claims.role) {
      setUserRole(claims.role)
    }
  }, [])

  
  const signOut = useCallback(async({redirect = '/'}) => {
    setLoadingResult(true)
    setSigningOut(true)
    return auth.signOut().then(() => {
      onSignOut.current.forEach((cb) => {
        cb()
      })
      setUser(null)
      setUserLevel(0)
      setLoadingResult(false)
      navigate(redirect)
    }).catch((err) => {
      setLoadingResult(false)
      console.log('signout error', err)
      setSigningOut(false)
      return {logout: false}
    })
  }, [auth])

  const updateUserLevel = useCallback(async () => {
    if (f && f.httpsCallable) {
      const { data } = await f.httpsCallable(f.functions, 'backend-onUpdateUser')({});
      // setUser(data.userRecord)
      if (data.userRecord) {
        levelClaims(data.userRecord.customClaims)
        await auth.currentUser.getIdToken(true)  
      }
    }
  }, [auth, f, levelClaims])

  const linkEmail = useCallback(async (auth, email, password) => {
    if (links) {
      const credential = links.EmailAuthProvider.credential(email, password);
      const userCred = await links.linkWithCredential(auth.currentUser, credential)
      await updateUserLevel()
      setUser(userCred.user)
      setIsAnon(userCred.user.isAnonymous)   
    }
  }, [links, updateUserLevel])


  const getAndSetClaims = useCallback(async (currentUser) => {
    const tokenResult = await currentUser.getIdTokenResult(true)
    levelClaims(tokenResult.claims)
    if (!!tokenResult.token) {
      setVerificationToken({token: tokenResult.claims.token, tokenLength: tokenResult.claims.tokenLength})
    }
  }, [levelClaims])


  const sendWhatsappVerificationCode = useCallback(async(to) => {
    if (f) {
      return await f.httpsCallable(f.functions, 'whatsapp-verificationCode')({ to })
    }
  }, [f])
  const validateWhatsappVerificationCode = useCallback(async(code) => {
    if (f) {
      try {
        const result = await f.httpsCallable(f.functions, 'whatsapp-validateCode')({ code })
        // console.log('result', result)
        if (result && result.data && result.data.success) {
          await updateUserLevel()
        }
        return result
      } catch (err) {
        console.log('validateWhatsappVerificationCode', err)
      }
    }
  }, [f, updateUserLevel])

  // getRedirectResult
  useEffect(() => {
    const loader = async () => {
      const {
        signInAnonymously,
        confirmPasswordReset,
        onAuthStateChanged, 
        linkWithRedirect,
        getRedirectResult, 
        GoogleAuthProvider,
        FacebookAuthProvider,
        EmailAuthProvider,
        linkWithCredential,
        updateProfile,
        sendEmailVerification,
        OAuthProvider,
        updatePassword,
        sendPasswordResetEmail,
        signInWithCredential,
        signInWithEmailAndPassword,
        reauthenticateWithCredential,
      } = await import('firebase/auth');
      setLinks({
        linkWithRedirect,
        linkWithCredential,
        getRedirectResult,
        GoogleAuthProvider,
        FacebookAuthProvider,
        signInAnonymously,
        signInWithEmailAndPassword,
        signInWithCredential,
        EmailAuthProvider,
        onAuthStateChanged,
        OAuthProvider,
      })
      setProfileAccess({
        updateProfile,
        sendEmailVerification,
        updatePassword,
        sendPasswordResetEmail,
        confirmPasswordReset,
        reauthenticateWithCredential,
      })
      // console.log('links loaded')
    }
    if (auth) {
      loader()
    }

  },[auth])

  const verificationActive = useCallback(() => {
    if (auth && user && verificationToken) {
      const current = Date.now()
      return (verificationToken.token + verificationToken.tokenLength) >= current
    } else {
      return false
    }
  }, [auth, user, verificationToken])

  useEffect(() => {
    const getRedirectResult = async () => {
      try {
        const result = await links.getRedirectResult(auth)
        if (result) {
          const user = result.user;
          setUser(user)
          setIsAnon(user.isAnonymous)
          await updateUserLevel()
          auth.currentUser.getIdToken(true)
          getAndSetClaims(auth.currentUser)
          setLoadingResult(false)
        } else {
          setLoadingResult(false)
        }
      } catch (error) {
        if (error.code && error.code === 'auth/credential-already-in-use') {
          const credential = links.OAuthProvider.credentialFromError(error)
          links.signInWithCredential(auth, credential).then(() => {
            setLoadingResult(false)
          });
        } else {
          console.log('error', error)
          setLoadingResult(false)
        }
      }
    }
    if (auth && links && !signingOut) {
      // console.log('=========== CHECK REDIRECT RESULT ===========')
      getRedirectResult()
    }
  },[auth, links, updateUserLevel, getAndSetClaims, signingOut])

  useEffect(() => {
    const signIn = async() => {
      if (!auth.currentUser) {
        links.signInAnonymously(auth)
        .then(async(auth) => {
          await updateUserLevel()
          // console.log('signed in ======>', auth.user)
          setUserRole('anonymous')
          setUserLevel(0)
          // Signed in..
        })
        .catch((error) => {
          console.log('linkGoogle error', error)
          // links.signInWithCredential(error.credential)
  
          const errorCode = error.code;
          const errorMessage = error.message;
          // ...
        });  
      }
      links.onAuthStateChanged(auth, async (authUser) => {
        if (authUser && !signingOut) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          console.log('==================')
          console.log('==================')
          console.log('onAuthStateChanged')
          console.log('==================')
          console.log('==================')
          onAuthStateChange.current.forEach((cb) => {
            cb()
          })    
          setUser(authUser)
          setIsAnon(authUser.isAnonymous)
          await updateUserLevel()
          getAndSetClaims(auth.currentUser)

          // ...
        } else {
          // User is signed out
          // ...
        }  
      })
    }
    // console.log('auth.currentUser', auth && auth.currentUser)
    // console.log('user', user)
    if (auth && links && !loadingResult && !user && !signingOut) {
      signIn()
    }
  }, [auth, isAnon, user, loadingResult, links, updateUserLevel, getAndSetClaims, signingOut])

  const subscribeOnAuthStateChange = (callback) => {
    onAuthStateChange.current.set(callback.name, callback)
  }

  const subscribeOnSignIn = (callback) => {
    onSignIn.current.set(callback.name, callback)
  }
  const subscribeOnSignOut = (callback) => {
    onSignOut.current.set(callback.name, callback)
  }

  const unsubscribeOnSignIn = (callback) => {
  }
  const unsubscribeOnSignOut = (callback) => {
  }

  const updateAvatar = useCallback(async(photoURL) => {
    if (f && userLevel > 2) {
      try {
        const result = await f.httpsCallable(f.functions, 'backend-updateUserAvatar')({ photoURL })
        // console.log('result', result)
        return result
      } catch (err) {
        console.log('updateUserAvatar', err)
      }
    }
  }, [f, userLevel])

  const updateDisplayName = useCallback(async(displayName) => {
    if (f && userLevel > 1) {
      try {
        const result = await f.httpsCallable(f.functions, 'backend-updateUserDisplayName')({ displayName })
        // console.log('result', result)
        return result
      } catch (err) {
        console.log('updateUserDisplayName', err)
      }
    }
  }, [f, userLevel])


  return {
    auth,
    getAndSetClaims,
    isAnon,
    linkGoogle,
    linkFacebook,
    linkEmail,
    links,
    profileAccess,
    sendWhatsappVerificationCode,
    setUser,
    signOut,
    signingOut,
    setSigningOut,
    subscribeOnAuthStateChange,
    subscribeOnSignIn,
    subscribeOnSignOut,
    unsubscribeOnSignIn,
    unsubscribeOnSignOut,
    updateAvatar,
    updateDisplayName,
    updateUserLevel,
    user, 
    userLevel,
    userRole,
    validateWhatsappVerificationCode,
    verificationActive,
    verificationToken,
  }
}

export default useAnonAuth;