import React, { useEffect, useState, useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { UserGamesContext } from '../GamesLayout'
import { FirebaseFirestoreContext } from '../GamesLayout';
import { FirebaseUserContext } from '../GamesLayout';
import useLovedGame, { Love } from '../../hooks/useLovedGame';
import usePlayedGame, { Played } from '../../hooks/usePlayedGame';
import useWantToPlayGame, { WantToPlay } from '../../hooks/useWantToPlayGame';
import useIsMounted from '../../hooks/useIsMounted';
// import Img from 'gatsby-image';
import { GatsbyImage } from "gatsby-plugin-image"

import CardActionArea from '../CardActionArea';

import Button from '../Button';
import RadarColorIcon from './RadarIcon';
import { presentSellInfo, bgColor } from '../Game/SellBadge';
import NewBoxIcon from '../Game/Icons/NewBox';
import NewRocketIcon from '../Game/Icons/NewRocket';
import NotificationIcon from '../Game/Icons/Notification'

// import { cardWidth, imageContainerWidth } from '../GameControlNavigator'

const LabeledBox = (props) => {
  const theme = useTheme();
  const style = {
    color: theme.palette.getContrastText(bgColor(theme, 'ee')[props.type]),
    backgroundColor: bgColor(theme, 'ee')[props.type],
  }
  return (
    <Box className={props.classes.label} style={style}>
      {props.children}
    </Box>
  )
}

export const imageToDisplay = (game) => {
  const gameForSaleInfo = game && game.game_for_sale && game.game_for_sale[0];
  if (gameForSaleInfo) {
    if (gameForSaleInfo.image && gameForSaleInfo.image.gatsbyImageData) {
      return gameForSaleInfo.image.gatsbyImageData;
    }
  }
  return game && game.image && game.image.gatsbyImageData;
}

const GameImage = (props) => {
  const {
    game,
    commingSoon,
    commingSoonText,
    liveInfo,
    isForSale,
    type,
    classes: gridClasses,
    // matchedQuery,
  } = props;
  const classNameForGame = (!isForSale && commingSoon) ? 'comingGameImage' : 'availableGameImage';
  const image = imageToDisplay(game);
  // const [loadingImage, setLoadingImage] = useState(true)
  // const width = matchedQuery ? cardWidth[matchedQuery].minWidth : 0;
  return (
    <React.Fragment>
    {image &&
      <React.Fragment>
        <Box className={gridClasses.imageContainer}>
          <GatsbyImage image={image} className={gridClasses[classNameForGame]} alt={game.title} objectFit={"contain"} />
          <Box className={gridClasses.labels}>
          {!isForSale && commingSoon &&
            <LabeledBox classes={gridClasses} type={type} >
              <RadarColorIcon className={gridClasses.boxIcon} color="primary" />
              <Typography variant="body2" className={gridClasses.labelText}>{commingSoonText}</Typography>
            </LabeledBox>
          }
          {isForSale && liveInfo && liveInfo.saleInfo && liveInfo.saleInfo.inStock &&
            <React.Fragment>
              <LabeledBox classes={gridClasses} type={type}>
                <NewBoxIcon className={gridClasses.boxIcon} />
                <Typography variant="body2" className={gridClasses.labelText}>En Venta!!!</Typography>
              </LabeledBox>
            </React.Fragment>
          }
          {isForSale && liveInfo && liveInfo.saleInfo && liveInfo.saleInfo.preorderAvailable &&
            <React.Fragment>
              <LabeledBox classes={gridClasses} type={type} >
                <NewRocketIcon className={gridClasses.boxIcon} height={50} width={50} />
                <Typography variant="body2" className={gridClasses.labelText}>Pre Venta!!!</Typography>
              </LabeledBox>
            </React.Fragment>
          }
          {isForSale && liveInfo && liveInfo.saleInfo && !liveInfo.saleInfo.inStock && !liveInfo.saleInfo.preorderAvailable &&
            <React.Fragment>
              <LabeledBox classes={gridClasses} type={type}>
                <NotificationIcon className={gridClasses.boxIcon} height={50} width={50} />
                <Typography variant="body2" className={gridClasses.labelText}>Avisame!!!</Typography>
              </LabeledBox>
            </React.Fragment>
          }
          {isForSale && liveInfo && liveInfo.lastUnit &&
            <React.Fragment>
              <Box className={gridClasses.lastUnit}>
                <Typography variant="body2" align="center">
                {liveInfo.lastUnitText}
                </Typography>
              </Box>
            </React.Fragment>
          }
          
          </Box>

        </Box>
      </React.Fragment>
    }
    </React.Fragment>

  )
}

const GameCard = (props) => {
  const isMounted = useIsMounted();
  const [liveInfo, setLiveInfo] = useState();
  const firestore = useContext(FirebaseFirestoreContext);
  const userContext = useContext(FirebaseUserContext);
  const { 
    game,
    city,
    gameForSaleInfo, 
    liveInfoLoaded, 
    classes: gridClasses, 
    useBase64Image,
    matchedQuery,
    loved,
    played,
    wantToPlay,
    userGames,
  } = props;
  const commingSoon = (!game.available && game.available === false);
  const [gameType, setGameType] = useState((commingSoon) ? 'commingSoon' : 'forPlay');

  const isForSale = Boolean(game.game_for_sale && game.game_for_sale[0] && game.game_for_sale[0]);
  const commingSoonText = (commingSoon) ? 'próximamente': '';
  const title = (isForSale) ? game.game_for_sale[0].title : game.title;
  const gameOrExpansion = (game.expansion) ? 'expansion' : 'juego';

  const publishEvent = (ev) => {
    const data = window.dataLayer;
    data.push({event: 'select_item', items: [
      {
        item_id: game.id,
        item_name: game.title,
      }
    ]})
  }

  useEffect(() => {
    if (isMounted && liveInfoLoaded) {
      const info = presentSellInfo(game, gameForSaleInfo);
      setLiveInfo(info);
    } 
  }, [game, gameForSaleInfo, liveInfoLoaded, isMounted])
  useEffect(() => {
    if (isMounted && liveInfo && liveInfo.type) {
      setGameType(liveInfo.type);
    }
  },[liveInfo, isMounted]);

  const { lovedState, addFavorite } = useLovedGame(firestore, userContext.user, userGames, game.id, isMounted)
  const { playedState, addPlayed } = usePlayedGame(firestore, userContext.user, userGames, game.id, isMounted)
  const { wantToPlayState, addWantToPlay } = useWantToPlayGame(firestore, userContext.user, userGames, game.id, isMounted)
  return (
  <Card className={gridClasses.gameCard}>
    {/* <CardActionArea onClick={publishEvent} disabled={!isMounted}  to={`/${city}/${gameOrExpansion}/${game.contentful_id}/${game.slug}/`} > */}
    <CardActionArea onClick={publishEvent} disabled={!isMounted}  to={`/ba/${gameOrExpansion}/${game.contentful_id}/${game.slug}/`}>
      <GameImage
        {...props}
        commingSoon={commingSoon}
        commingSoonText={commingSoonText}
        liveInfo={liveInfo}
        isForSale={isForSale}
        type={gameType}
        classes={gridClasses}
        useBase64Image={useBase64Image}
        matchedQuery={matchedQuery}
      />
      <CardContent className={gridClasses.cardContent}>
        <Box className={gridClasses.title}>
          <Typography gutterBottom variant="h5" component="h2" key="title" className={gridClasses.title}>
            {title}
          </Typography>
        </Box>
        <Typography variant="body2" color="textSecondary" component="p" key="info">
          Juegan: {game.minimumPlayers}-{game.maximumPlayers}
        </Typography>
      </CardContent>
    </CardActionArea>
    {!isForSale &&
    <CardMedia>
    {/* <CardActionArea onClick={publishEvent} disabled={!navigationEnabled}  to={`/${city}/${gameOrExpansion}/${game.contentful_id}/${game.slug}`} > */}
      <Grid
        container
        className={gridClasses.bottomBox}
        direction="row"
        justify="space-evenly"
        alignItems="center"
        spacing={0}
      >
        <Grid item>
          <WantToPlay add={addWantToPlay(wantToPlayState)} wantToPlay={wantToPlayState} />
          <Played addFavorite={addPlayed(played)} played={playedState} />
          <Love addFavorite={addFavorite(loved)} loved={lovedState} />
        </Grid>
        {/* <Grid item className={gridClasses.bottom}>

          <Button onClick={publishEvent} disabled={!isMounted} variant="outlined" size="small" color="primary" to={`/${city}/${gameOrExpansion}/${game.contentful_id}/${game.slug}`}>
            Ver Juego
          </Button>
        </Grid> */}
      </Grid>
    {/* </CardActionArea> */}

    </CardMedia>

    }

    {isForSale && liveInfo && liveInfo.saleInfo &&
    <CardMedia>
      {/* <CardActionArea onClick={publishEvent} disabled={!isMounted}  to={`/${city}/${gameOrExpansion}/${game.contentful_id}/${game.slug}`} > */}
      <CardActionArea onClick={publishEvent} disabled={!isMounted}  to={`/${gameOrExpansion}/${game.contentful_id}/${game.slug}`} >
        <Grid
          container
          className={gridClasses.priceBox}
          direction="row"
          justify="space-between"
          spacing={0}
          alignItems="center"
        >
          <Grid item>
            <WantToPlay add={addWantToPlay(wantToPlay)} wantToPlay={wantToPlayState} />

            <Played addFavorite={addPlayed(played)} played={playedState} />

            <Love addFavorite={addFavorite(loved)} loved={lovedState} />
          </Grid>

          {/* <Grid item className={gridClasses.bottom} >
            <Button disabled={!isMounted} variant="outlined" size="small" color="primary">
              Ver Juego
            </Button>
              
          </Grid> */}

          <Grid item className={gridClasses.bottomPrice}>
            <Box className={gridClasses.priceValue}>
            <Typography variant="body1" className={gridClasses.priceText} align="right" component="p" key="price">
              $ {liveInfo.saleInfo.shopPrice}
            </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardActionArea>

    </CardMedia>
  }
  {isForSale && !liveInfo && <CardMedia>
    <Box className={gridClasses.loading}>
      <CircularProgress />
    </Box>
    </CardMedia>}
  </Card>
  )

}

export const VirtuosoCardGenerator = (db, props) => {
  // console.log('db', db)
  const userGames = useContext(UserGamesContext)
  return (key, index) => {
  // console.log('k', key)
    const game = db[key];
    if (!game) return null;
    const {
      gridOptions,
      gameForSaleInfo,
      city,
      liveInfoLoaded,
      gridClasses,
      matchedQuery,
    } = props;
    const loved = userGames && userGames.userGames && userGames.userGames.loved && userGames.gameInList(userGames.userGames.loved.games, game.id)
    const played = userGames && userGames.userGames && userGames.userGames.played && userGames.gameInList(userGames.userGames.played.games, game.id)
    const wantToPlay = userGames && userGames.userGames && userGames.userGames.wantToPlay && userGames.gameInList(userGames.userGames.wantToPlay.games, game.id)
    return (
      <Grid key={game.id} item xs={gridOptions.xs} sm={gridOptions.sm} md={gridOptions.md} lg={gridOptions.lg} xl={gridOptions.xl}>
        <GameCard 
          userGames={userGames}
          loved={loved}
          played={played}
          wantToPlay={wantToPlay}
          game={game}
          city={city}
          gameForSaleInfo={gameForSaleInfo}
          liveInfoLoaded={liveInfoLoaded}
          classes={gridClasses}
          matchedQuery={matchedQuery}
        />
      </Grid>
    )
  }
}

export default GameCard;
