import React, {useEffect, useState } from 'react'
import FavoriteBorderTwoToneIcon from '@material-ui/icons/FavoriteBorderTwoTone';
import FavoriteTwoToneIcon from '@material-ui/icons/FavoriteTwoTone';
import IconButton from '@material-ui/core/IconButton';

export const LoveIcon = props => {
  const { fontSize = 'default' } = props;
  return (
    <FavoriteTwoToneIcon fontSize={fontSize} style={{ color: 'rgb(255, 0, 124)' }}/>
  )
}

export const Love = props => {
  const { addFavorite, loved } = props;
  return (
      <IconButton aria-label="favorito" onClick={addFavorite}>
        {loved && <LoveIcon />}
        {!loved && <FavoriteBorderTwoToneIcon />}
      </IconButton>
  )
}

const useLovedGame = (firestore, user, userGames, gameId, isMounted = true) => {
  const [lovedState, setLovedState] = useState()
  const addFavorite = () => async (ev) => {
    ev.stopPropagation()
    ev.preventDefault()
    ev.persist()
    if (isMounted && firestore && firestore.firestoreLite) {
      await userGames.addGameToList(userGames.userGames.loved.listId, gameId)
      setLovedState(!lovedState)
    }
  }
  useEffect(() => {
    if (isMounted && lovedState === undefined && userGames && userGames.userGames && userGames.userGames.loved) {
      const loved = userGames.gameInList(userGames.userGames.loved.games, gameId)
      setLovedState(loved)
    }
  },[userGames, lovedState, gameId, isMounted])
  
  return {
    lovedState,
    setLovedState,
    userGames,
    addFavorite,
  }
}

export default useLovedGame;


