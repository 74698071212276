import { useEffect, useState } from 'react';
import { connect } from 'getstream';

export const getFeedForEnv = (feedName, ignoreEnv = false) => (process.env.NODE_ENV === 'production' || ignoreEnv) ? feedName : `${feedName}_dev`

const useStreamFeedNotifications = (token, userContext) => {
  const [client, setClient] = useState();
  const [streamUser, setStreamUser] = useState();
  useEffect(() => {
    const streamClientsignOut = () => {
      setClient(null)
      setStreamUser(null)
    }
    const getClient = async (tk, uid) => {
      const apiKey = process.env.GATSBY_GET_STREAM_KEY;
      const appId = process.env.GATSBY_GET_STREAM_APP;
      const cli = connect(apiKey, tk, appId);

      const user = cli.feed(getFeedForEnv('notification'), uid, tk);
      setClient(cli)
      setStreamUser(user)
      userContext.subscribeOnSignOut(streamClientsignOut)
      userContext.subscribeOnAuthStateChange(streamClientsignOut)
    }
    if (!client && !streamUser && token && userContext && userContext.user && userContext.user.uid && !userContext.signingOut) {
      return getClient(token, userContext.user.uid).then(() => {
        return () => {
        }  
      })
    }
  }, [client, streamUser, token, userContext])
  const addGameActivity = async (verb, object) => {
    if (client) {
      // const gamesNotifications = user.feed('games', 'all')
      // console.log('gamesNotifications', gamesNotifications)
      const activity = {
        verb, 
        object,
        time: new Date(),
      }
      const result = await streamUser.addActivity(activity)
  
      const gamesNotifications = client.feed(getFeedForEnv('games'), userContext.user.uid, token)
      const gameActivity = {
        actor: client.streamUser, 
        verb,
        object,
      }
      console.log('gameActivity', gameActivity)
      gamesNotifications.addActivity(gameActivity)

      return result;
  
    }
    return null;
  }

  return {
    streamUser,
    client,
    addGameActivity,
  }
}

export default useStreamFeedNotifications;