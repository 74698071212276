import * as React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function SvgComponent(props) {
  return (
    <SvgIcon
      width={360.918}
      height={360.918}
      viewBox="0 0 360.918 360.918"
      {...props}
    >
      <circle fill="#76C5E4" cx={180.459} cy={180.459} r={180.459} />
      <circle fill="#FFF" cx={180.459} cy={180.46} r={157.341} />
      <circle
        fill="#F15A29"
        stroke="#FFF"
        strokeWidth={3}
        strokeMiterlimit={10}
        cx={180.459}
        cy={180.46}
        r={130.697}
      />
      <g fill="#FFF">
        <path d="M174.311 217.356l3.729-42.51-6.397-9.211s-5.687-72.707-7.951-83.598h-34.094v72.786l26.151 25.885-26.151 26.151v72.786h35.963c.061-.667 4.327-29.837 6.811-44.616-2.062-1.965-4.458-4.739-4.458-7.811 0-4.385 2.632-8.161 6.397-9.862zM194.437 82.038c-2.245 10.891-7.949 83.598-7.949 83.598l-6.385 9.211 3.717 42.51c3.772 1.701 6.406 5.477 6.406 9.863 0 3.071-2.388 5.846-4.476 7.811 2.51 14.779 6.758 43.949 6.826 44.616h35.965V206.86l-26.152-26.151 26.152-25.885V82.038h-34.104z" />
      </g>
    </SvgIcon>
  )
}

export default SvgComponent;
