import {useEffect, useState} from 'react';

const useFirebaseAuth = (fbase) => {
  const [auth, setAuth] = useState()  
  const [emuConnected, setEmuConnected] = useState()  

  useEffect(() => {
    const loadAuth = async() => {
      // eslint-disable-next-line no-undef
      if (process.env.NODE_ENV === 'production') {
        const { getAuth } = await import('firebase/auth');
        const authInstance = getAuth(fbase)
        setAuth(authInstance)    
      } else {
        const { getAuth, connectAuthEmulator } = await import('firebase/auth');
        const authInstance = getAuth(fbase)
        try {
          connectAuthEmulator(authInstance, "http://localhost:9099");
          // connectAuthEmulator(authInstance, 'http://192.168.100.35:9099');
          setEmuConnected(true)
          } catch (err) {
            console.log('already connected', err)
          }
        setAuth(authInstance)
      }
    }
    if (fbase && !auth) {
      loadAuth()
    }
  }, [fbase, auth, emuConnected])
  return auth
}

export default useFirebaseAuth;

