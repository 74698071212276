import { useEffect, useState } from 'react'

const useGetStreamSubscription = (getStream, callback, success, fallback) => {
  const [subscription, setSubscription] = useState();

  useEffect(() => {
    const subs = async () => {
      const s = getStream.streamUser.subscribe(callback)
      s.then(success, fallback);
      setSubscription({ cancel: s.cancel })
    }
    if (!subscription && getStream && getStream.streamUser) {
      subs();

      return () => {
        subscription && subscription.cancel()
      }
    }
  }, [getStream, callback, subscription, success, fallback])
}

export default useGetStreamSubscription;