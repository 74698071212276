import { useEffect, useState, useRef, useContext, useCallback } from 'react';
import getJson from '../lib/network/getJson';
import { UserGamesContext } from '../components/GamesLayout';
import { useLocation } from "@reach/router"

const MAP_COLLECTION = {
  loved: 'loved',
  played: 'played',
  wantToPlay: 'wantToPlay',
}


const matchers = [
  {
    pathname: '/ba/juegos',
    search: 'collection',
    value: 'loved'
  },
  {
    pathname: '/ba/juegos',
    search: 'collection',
    value: 'played'
  },
  {
    pathname: '/ba/juegos',
    search: 'collection',
    value: 'wantToPlay'
  },
]

const urlHas = (search, key) => {
  const urlParams = new URLSearchParams(search);
  return urlParams.get(key) || '';
}

const findMatch = (location, subscriptions) => {
  return subscriptions.find((subs) => {
    return subs.pathname === location.pathname && urlHas(location.search, subs.search) === subs.value
  })
} 

const detectCollection = (location) => {
  const matched = findMatch(location, matchers);
  return matched && matched.value || null;
}


const useSearch = (props) => {
  const { staticSearch = false, useIndex = false } = props;
  const searchEnabled = (staticSearch) ? Boolean(props.items) : Boolean(props.filename || useIndex);
  const filename = (props.filename) ? `${props.filename}` : (useIndex) ? 'ba-new.json' : false
  const [searchIndex, setSearch] = useState();
  const [fuse, setFuse] = useState();
  const [searchResult, setSearchResult] = useState();
  const [needsSearch, setNeedsSearch] = useState(true)
  const preSearch = useRef()
  const preSearchDone = useRef(false)
  const userGames = useContext(UserGamesContext)
  const mountedRef = useRef(true)
  const colChanged = useRef(true)
  const location = useLocation()
  const [collection, setCollection] = useState()

  const filterCollection = useCallback((game, index) => {
    if (!collection) return true
    if (!MAP_COLLECTION[collection]) return true
    return userGames && userGames.userGames[MAP_COLLECTION[collection]].includes(game.id)
  }, [userGames, collection])
  

  useEffect(() => {
    if (location) {
      setCollection(detectCollection(location))
    }
  }, [location])


  useEffect(() => {
    const searchOptions = {
      shouldSort: true,
      threshold: 0.2,
      location: 0,
      distance: 50,
      includeScore: true,
      minMatchCharLength: 3,
      keys: [{
        name: 'title',
        weight: 0.8,
      }, {
        name: 'names',
        weight: 0.2,
      }]
    }
  
    const fetchStatic = async () => {
      const fuse = await import('fuse.js');
      if (!mountedRef.current) return null;
      const options = {
        ...searchOptions,
        keys: ['title', 'names']
      }
      const theFuse = new fuse.default(props.items.filter(filterCollection), options)
      setFuse(theFuse);
      preSearch.current = urlHas('search');
      // console.log('preSearch.current', preSearch.current)
      setSearch(props.items.filter(filterCollection));
      // console.log('filter', preSearch.current, urlHas('search'), needsSearch, preSearch.current.length > 0 )
      if (preSearch.current !== undefined && preSearch.current.length > 0) {
        // console.log('doing presearch')
        setNeedsSearch(true)
        preSearchDone.current = true;
        const result = theFuse.search(preSearch.current).map((e) => e.item);

        setSearchResult(result.filter(filterCollection));
        // console.log('preSearchDone.current', preSearchDone.current)
      } else {
        // console.log('presearch not required')
        setNeedsSearch(false);
        preSearchDone.current = true;
      }
    }

    const fetchHandler = async () => {

      const path = (process.env.NODE_ENV === 'production') ? `https://storage.googleapis.com/pamir-public-db/index/` : `/data/index/`
      return getJson(`${path}${filename}`).then(async (json) => {
        // console.log('json', `${path}${filename}`, json)
        // console.log('initialize search')
        if (!mountedRef.current) return null;
        const fuse = await import('fuse.js');
        if (!mountedRef.current) return null;
        const items = json.items.filter(filterCollection)
        const options = {
          ...searchOptions,
          keys: ['title', 'names']
        }
        const theFuse = new fuse.default(items, options)
        setFuse(theFuse);
        preSearch.current = urlHas('search');
        // console.log('preSearch.current', preSearch.current)

        setSearch(items);
        // console.log('filter', preSearch.current, needsSearch.current, preSearch.current.length > 0 )
        if (preSearch.current !== undefined && preSearch.current.length > 0) {
          // console.log('doing presearch')
          setNeedsSearch(true)
          preSearchDone.current = true;
          const result = theFuse.search(preSearch.current).map((e) => e.item);
          setSearchResult(result.filter(filterCollection));
          // console.log('preSearchDone.current', preSearchDone.current)
        } else {
          // console.log('presearch not required')
          setNeedsSearch(false);
          preSearchDone.current = true;
        }

      });


    };

    if (mountedRef.current && userGames && userGames.userGames && !searchIndex && searchEnabled) {
      if (staticSearch) {
        
        fetchStatic();
      } else {
        fetchHandler();

      }
      colChanged.current =false

    }
  }, [filterCollection, searchResult, userGames, filename, searchIndex, searchEnabled, props.items, staticSearch, collection, setSearchResult]); 

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [mountedRef])  

  return {
    searchEnabled,
    searchIndex,
    setSearch,
    fuse,
    searchResult,
    setSearchResult,
    needsSearch,
    setNeedsSearch,
    preSearch: preSearch.current,
    preSearchDone: preSearchDone.current,
    collection,
    setCollection,
  }
}

export default useSearch;




