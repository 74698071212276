import React, {useContext, useState, useEffect} from 'react';
import Box from '@material-ui/core/Box'
import useGetStreamSubscription from '../../../hooks/useGetStreamSubscription';
import {GetStreamClientContext} from '../../../components/GamesLayout'

const NotificationIcon = props => {
  const getStream = useContext(GetStreamClientContext);
  const [notify, setNotify] = useState()
  // console.log('getStream', getStream)
  // console.log('props', props)
  const handleNotification1 = (data) => {
    // console.log('data1', data.new)
  }
  const success = (data) => {
    console.log('success', data)
  }
  const fallback = (data) => {
    console.log('fallback', data)
  }
  useGetStreamSubscription(getStream, handleNotification1, success, fallback)

  return (
    <>
      
    </>

  )
}

export default NotificationIcon;