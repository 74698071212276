import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Typography from '@material-ui/core/Typography';

import MenuBar from '../MenuBar';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  active: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    textDecoration: 'none',
    '&:link': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
    '&:-webkit-any-link': {
      color: theme.palette.text.primary,
      textDecoration: 'none',

    },
    '&:visited': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    }  
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:link': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
    '&:-webkit-any-link': {
      color: theme.palette.text.primary,
      textDecoration: 'none',

    },
    '&:visited': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    }  
  }
}));

const HideOnScroll = (props) => {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function TopBar(props) {
  const classes = useStyles();
  const { useHeader } = props;
  if (useHeader === false) return null;

  return (
    <div className={classes.root}>
      <HideOnScroll {...props}>
        <AppBar color="primary">
          <Toolbar id="back-to-top-anchor">
            <MenuBar />
            <Typography variant="h6" component="h1" className={classes.title}>
              Conexión Berlin
            </Typography>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </div>
  );
}