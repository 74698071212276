import React, { Fragment } from 'react';
import Container from '@material-ui/core/Container';
import TopBar from '../components/TopBar';
import Version from '../components/Version';
import Contact from '../components/Contact';
import DataFiscal from '../components/DataFiscal';
import useFirebase from '../hooks/useFirebase';
import useFirebaseAuth from '../hooks/useFirebaseAuth';
import useAnonAuth from '../hooks/useAnonAuth';
import useFirebaseFirestore from '../hooks/useFirebaseFirestore';
import SearchContext from '../components/TopBarGames/context';
import useSearch from '../hooks/useSearch';
import useFirebaseFunctions from '../hooks/useFirebaseFunctions';

export const FirebaseContext = React.createContext();
export const FirebaseAuthContext = React.createContext();
export const FirebaseUserContext = React.createContext();
export const FirebaseFirestoreContext = React.createContext();

const Layout = (props) => {
  const search = useSearch({ filename: props.searchFilename });
  const fbase = useFirebase()
  const auth = useFirebaseAuth(fbase)
  const functions = useFirebaseFunctions(fbase);
  const { user, anonAuth } = useAnonAuth(auth, functions)
  const firestore = useFirebaseFirestore(fbase)
  return (
    <SearchContext.Provider value={search}>
      <FirebaseContext.Provider value={fbase}>
        <FirebaseAuthContext.Provider value={auth}>
          <FirebaseUserContext.Provider value={user}>
            <FirebaseFirestoreContext.Provider value={firestore}>
              <AppWithoutAuthentication {...props} user={user} auth={auth} firebase={fbase} anonAuth={anonAuth} />
            </FirebaseFirestoreContext.Provider>
          </FirebaseUserContext.Provider>
        </FirebaseAuthContext.Provider>
      </FirebaseContext.Provider>
    </SearchContext.Provider>
  );
}

const AppWithoutAuthentication = ({ children, useHeader = true, useContainer = true, ...props }) => {
  return (
  <Fragment>
    <TopBar useHeader={useHeader} {...props} />
    {useContainer &&
      <Container maxWidth="lg">
        {children}
        <Contact />
        <DataFiscal />
      </Container>
    }
    {!useContainer && <>
    {children}
    <Contact />
    </>}
    <Version />
  </Fragment>
)};

export default Layout;
