import React from 'react';

import CardActionArea from "@material-ui/core/CardActionArea";
import GatsbyLink from "../gatsby-link";

const GatsbyLinkedCardActionArea = props => {
  const { to, href } = props;
  const component = to || href ? GatsbyLink : `button`;

  return <CardActionArea component={component} {...props} />;
}

export default GatsbyLinkedCardActionArea;