import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    width: 100,
    zIndex: 5000,
    bottom: 0,
  },
  image: {
    margin: 'auto',
    width: '100%',
  }
}));

const Loading = props => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography variant="h5" component="p">Cargando...</Typography>
    </Box>
  )
}

export default Loading;
