import React, { Fragment } from 'react';
import Container from '@material-ui/core/Container';


import TopBarGames from './TopBarGames';
import Version from '../components/Version';
import Contact from '../components/Contact';
import DataFiscal from '../components/DataFiscal';
import useFirebase from '../hooks/useFirebase';
// import useFirebaseAppCheck from '../hooks/useFirebaseAppCheck';
import useFirebaseAuth from '../hooks/useFirebaseAuth';
import useAnonAuth from '../hooks/useAnonAuth';
import useUserGames from '../hooks/useUserGames';
// import useFirebaseFirestore from '../hooks/useFirebaseFirestore';
import useFirebaseFirestoreLite from '../hooks/useFirebaseFirestoreLite';
import SearchContext from '../components/TopBarGames/context';
import useSearch from '../hooks/useSearch';
import useGetStreamToken from '../hooks/useGetStreamToken';
import useGetStreamClient from '../hooks/useGetStreamClient';
import useFirebaseFunctions from '../hooks/useFirebaseFunctions';
import useEvents from '../hooks/useEvents';


export const FirebaseContext = React.createContext();
export const FirebaseAuthContext = React.createContext();
export const FirebaseUserContext = React.createContext();
export const FirebaseFirestoreContext = React.createContext();
// export const FirebaseAppCheckContext = React.createContext();
export const FirebaseFunctionsContext = React.createContext();
export const UserGamesContext = React.createContext();
export const GetStreamTokenContext = React.createContext();
export const GetStreamClientContext = React.createContext();
export const EventsContext = React.createContext();

const Layout = (props) => {
  const fbase = useFirebase();
  // const appCheck = useFirebaseAppCheck(fbase);
  const auth = useFirebaseAuth(fbase);
  const firestore = useFirebaseFirestoreLite(fbase);
  const functions = useFirebaseFunctions(fbase);
  const anonAuth = useAnonAuth(auth, functions);
  const userGames = useUserGames(firestore, anonAuth);
  const streamToken = useGetStreamToken(functions, anonAuth);
  const streamClient = useGetStreamClient(streamToken, anonAuth)
  const events = useEvents();
  
  return (
      <FirebaseContext.Provider value={fbase}>
        {/* <FirebaseAppCheckContext.Provider value={appCheck}> */}
          <FirebaseAuthContext.Provider value={auth}>
            <FirebaseUserContext.Provider value={anonAuth}>
              <FirebaseFunctionsContext.Provider value={functions}>
                <FirebaseFirestoreContext.Provider value={firestore}>
                  <UserGamesContext.Provider value={userGames}>
                    <GetStreamTokenContext.Provider value={streamToken}>
                      <GetStreamClientContext.Provider value={streamClient}>
                        <EventsContext.Provider value={events}>
                          <AppWithoutAuthentication {...props} user={anonAuth.user} auth={auth} firebase={fbase} anonAuth={anonAuth.anonAuth} />
                        </EventsContext.Provider>
                      </GetStreamClientContext.Provider>
                    </GetStreamTokenContext.Provider>
                  </UserGamesContext.Provider>
                </FirebaseFirestoreContext.Provider>
              </FirebaseFunctionsContext.Provider>
            </FirebaseUserContext.Provider>
          </FirebaseAuthContext.Provider>
        {/* </FirebaseAppCheckContext.Provider> */}
      </FirebaseContext.Provider>
  );
}

export const collectionTitles = {
  played: 'los que jugué',
  loved: 'favoritos',
  wantToPlay: 'quiero jugar',
}

const AppWithoutAuthentication = ({ children, useHeader = true, useContainer = true, displayDataFiscal = true, displayContact = true, withSearch = true, useIndex, ...props }) => {
  const search = useSearch({ filename: props.searchFilename, items: props.items, staticSearch: props.staticSearch, useIndex });
  const title = (search.collection !== '') ? collectionTitles[search.collection] : (props.title) ? props.title : null
  return (
  <Fragment>
   
    <SearchContext.Provider value={search}>
      <TopBarGames title={title} useHeader={useHeader} {...props} search={search} withSearch={withSearch} />
      {useContainer &&
        <Container maxWidth="lg">
          {children}
          {displayContact && <Contact />}
          {displayDataFiscal && <DataFiscal />}
        </Container>
      }
      {!useContainer && <>
      {children}
      {displayContact && <Contact />}
      </>}
      <Version />

    </SearchContext.Provider>


  </Fragment>
)};

export default Layout;
