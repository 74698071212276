import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import GameCard from '../GameCard';
import Arrow from '../Arrow';

const gridWidth = {
  xs: 6,
  sm: 4,
  md: 3,
  lg: 3,
  xl: 3,
}

const cardWidth = {
  fallback: {
    minWidth: 144, 
    maxWidth: 345,
  },
  sm: {
    minWidth: 172,
    maxWidth: 172,
  },
  md: {
    minWidth: 242,
    maxWidth: 242,
  },
  lg: {
    minWidth: 242,
    maxWidth: 242,
  },
  xl: {
    minWidth: 242,
    maxWidth: 242,
  },
}

const imageContainerWidth = {
  xs: {
    minWidth: 172,
    maxWidth: 300,
  },
  sm: {
    minWidth: 172,
    maxWidth: 300,
  },
  md: {
    minWidth: 242,
    maxWidth: 300,
  },
  lg: {
    minWidth: 242,
    maxWidth: 300,
  },
  xl: {
    minWidth: 242,
    maxWidth: 300,
  },
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    justifyContent: 'space-around',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: '100%',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  gameCard: {
    maxWidth: cardWidth.fallback.maxWidth,
    minWidth: cardWidth.fallback.minWidth,
    [theme.breakpoints.up('sm')]: {
      maxWidth: cardWidth.sm.maxWidth,
      minWidth: cardWidth.sm.minWidth,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: cardWidth.md.minWidth,
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: cardWidth.lg.minWidth,
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: cardWidth.xl.minWidth,
    },
  },
  cardContent: {
    padding: theme.spacing(1),
  },
  title: {
    height: (theme.typography.htmlFontSize * 1.5 * theme.typography.h5.lineHeight) * 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottom: {
    margin: theme.spacing(3),
  },
  bottomPrice: {
    margin: theme.spacing(2),
  },
  priceBox: {
    backgroundColor: `${theme.palette.primary.main}10`,
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    }
    
    // padding: theme.spacing(2),
  },
  priceValue: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
  },
  priceText: {
    fontWeight: 600,
    textAlign: 'center',
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageContainer: {
    position: 'relative',
    [theme.breakpoints.up('xs')]: {
      minWidth: imageContainerWidth.xs.minWidth,
      maxWidth: imageContainerWidth.xs.maxWidth,
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: imageContainerWidth.sm.minWidth,
      maxWidth: imageContainerWidth.sm.maxWidth,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: imageContainerWidth.md.minWidth,
      maxWidth: imageContainerWidth.md.maxWidth,
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: imageContainerWidth.lg.minWidth,
      maxWidth: imageContainerWidth.lg.maxWidth,
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: imageContainerWidth.xl.minWidth,
      maxWidth: imageContainerWidth.xl.maxWidth,
    },
    overflow: 'hidden',
  },
  availableGameImage: {
  },
  comingGameImage: {
    WebkitFilter: 'grayscale(1)',
    filter: 'grayscale(1)',
  },
  labels: {
    zIndex: 400,
    position: 'absolute',
    display: 'block',
    height: theme.typography.fontSize + (theme.spacing() * 2),
    width: '100%',
    bottom: 0,
    left: 0,
    // top: 144 - (theme.typography.fontSize + (theme.spacing() * 2)),
  },
  labelIcon: {
    // padding: theme.spacing() / 4,
  },
  labelText: {
    padding: theme.spacing() / 4,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  boxIcon: {
    position: 'absolute',
    right: 5,
    bottom: 5,
    fontSize: 46,
  },
  lastUnit: {
    position: 'absolute',
    display: 'block',
    backgroundColor: '#ffea00ed',
    color: '#333',
    width: '110%',
    bottom: 0,
    left: 0,
    textAlign: 'center',
    [theme.breakpoints.up('xl')]: {
      transform: 'rotate(-45deg) translate(80px,-180px)',
      webkitTransform: 'rotate(-45deg) translate(80px,-180px)',
      mozTransform: 'rotate(-45deg) translate(80px,-180px)',
    },
    [theme.breakpoints.only('lg')]: {
      transform: 'rotate(-45deg) translate(70px,-190px)',
      webkitTransform: 'rotate(-45deg) translate(70px,-190px)',
      mozTransform: 'rotate(-45deg) translate(70px,-190px)',
    },
    [theme.breakpoints.only('md')]: {
      transform: 'rotate(-45deg) translate(74px,-190px)',
      webkitTransform: 'rotate(-45deg) translate(74px,-190px)',
      mozTransform: 'rotate(-45deg) translate(74px,-190px)',
    },
    '@media (min-width: 740px) and (max-width: 959.95px)': {
      transform: 'rotate(-45deg) translate(84px,-160px)',
      webkitTransform: 'rotate(-45deg) translate(74px,-190px)',
      mozTransform: 'rotate(-45deg) translate(74px,-190px)',
    },
    '@media (min-width: 640px) and (max-width: 739.95px)': {
      transform: 'rotate(-45deg) translate(64px,-130px)',
      webkitTransform: 'rotate(-45deg) translate(74px,-190px)',
      mozTransform: 'rotate(-45deg) translate(74px,-190px)',
    },
    '@media (min-width: 600px) and (max-width: 639.95px)': {
      transform: 'rotate(-45deg) translate(54px,-120px)',
      webkitTransform: 'rotate(-45deg) translate(74px,-190px)',
      mozTransform: 'rotate(-45deg) translate(74px,-190px)',
    },
    '@media (min-width: 500px) and (max-width: 599.95px)': {
      transform: 'rotate(-45deg) translate(74px,-170px)',
      webkitTransform: 'rotate(-45deg) translate(74px,-190px)',
      mozTransform: 'rotate(-45deg) translate(74px,-190px)',
    },
    '@media (min-width: 400px) and (max-width: 499.95px)': {
      transform: 'rotate(-45deg) translate(54px,-140px)',
      webkitTransform: 'rotate(-45deg) translate(74px,-190px)',
      mozTransform: 'rotate(-45deg) translate(74px,-190px)',
    },
    // [theme.breakpoints.only('sm')]: {
    //   transform: 'rotate(-45deg) translate(64px,-150px)',
    //   webkitTransform: 'rotate(-45deg) translate(54px,-120px)',
    //   mozTransform: 'rotate(-45deg) translate(54px,-120px)',
    // },
    '@media (min-width:360px) and (max-width: 399.95px)': {
      transform: 'rotate(-45deg) translate(48px,-120px)',
      webkitTransform: 'rotate(-45deg) translate(48px,-120px)',
      mozTransform: 'rotate(-45deg) translate(48px,-120px)',
    },
    '@media (min-width:0px) and (max-width: 359.95px)': {
      transform: 'rotate(-45deg) translate(44px,-100px)',
      webkitTransform: 'rotate(-45deg) translate(44px,-90px)',
      mozTransform: 'rotate(-45deg) translate(44px,-90px)',
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(),
    // color: theme.palette.getContrastText(bgColor(theme, 'ee')[type]),
    // backgroundColor: bgColor(theme, 'ee')[type],
    height: theme.typography.fontSize + (theme.spacing() * 2),
    width: '100%',
  },
}));

const GameGrid = (props) => {
  const { liveInfoLoaded, gameForSaleInfo, scroller, doneFetching, pageSize } = props;
  const classes = useStyles();

  return (
    <Grid container spacing={3} id={`games-start-0`} className={classes.root}  >
    {!doneFetching && <Arrow />}
    {props.games && props.games.map((game, index) => {
      // console.log('game.id', game.id)
      const useBase64Image = (pageSize > index);
      return (
      <Grid key={game.id} item xs={gridWidth.xs} sm={gridWidth.sm} md={gridWidth.md} lg={gridWidth.lg} xl={gridWidth.xl}>
        <GameCard useBase64Image={useBase64Image} classes={classes} game={game} city={props.city} gameForSaleInfo={gameForSaleInfo} liveInfoLoaded={liveInfoLoaded} />
      </Grid>
    )})}
    </Grid>
  ) 
}

export default GameGrid;
