import React, { Fragment } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Spacer } from '../../templates/allgames';
import { Typography } from '@material-ui/core';
import whatsapp from './images/whatsapp.svg'
import email from './images/email.svg'
import instagram from './images/instagram.svg'
import facebook from './images/facebook.svg'
import telegram from './images/telegram.svg'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    minHeight: 820,
    [theme.breakpoints.up('md')]: {
      minHeight: 480,
    },
  },
  paper: {
    padding: theme.spacing(2),
    minWidth: 300,
    minHeight: 205,
    textAlign: 'center',
    margin: 'auto',    
  },
  footer: {
    padding: theme.spacing(2),
    textAlign: 'center',
    margin: 'auto',    
    minWidth: 300,
    minHeight: 140,
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:visited': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
    '&:hover': {
      color: theme.palette.text.primary,
      textDecoration: 'underline',
    }
  },
  svgIcon: {
    width: 64,
    height: 64,
  }
}));

const Contact = (props) => {
  const classes = useStyles();
  return (
    <Fragment> 
      <Spacer />
      <Grid container className={classes.root} direction="row"
      justify="center"
      alignItems="center" spacing={0}>
        <Grid item xs={12} sm={12} lg={3} md={4} container justify="center" spacing={0} alignItems="center">
          <Box p={1}>

          <Paper className={classes.paper} elevation={3}>
            <Grid container justify="center" alignItems="center" direction="column" >
              <Grid item>
                <Box component="div">
                  <a href="https://telegram.me/conexionberlin">
                    <img src={telegram} className={classes.svgIcon} alt="telegram Icon" title="contactanos por telegram" />
                  </a>
                </Box>
                <Box component="div" m={1}>
                  <a href="https://telegram.me/conexionberlin" color="inherit" className={classes.link} rel="noreferrer" target="_blank">
                    <Typography variant="h6" component="p">
                      Telegram @conexionBerlin
                    </Typography>
                  </a>
                  <Typography variant="body1">
                    Envianos un mensaje. Podes reservar por este medio.
                  </Typography>              
                </Box>
              </Grid>
            </Grid>
          </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} lg={3} md={4} container justify="center" spacing={0} alignItems="center">
          <Box p={1}>
            <Paper className={classes.paper} elevation={3}>
              <Grid container justify="center" alignItems="center" direction="column">
                <Grid item>
                  <Box component="div">
                    <a href="https://api.whatsapp.com/send?phone=541165199287&text=Contacto" >
                      <img src={whatsapp} className={classes.svgIcon} alt="whatsapp Icon" title="contactanos por whatsapp" />
                    </a>
                  </Box>
                  <Box component="div" m={1}>
                    <a href="https://api.whatsapp.com/send?phone=541165199287&text=Contacto" color="inherit" className={classes.link} rel="noreferrer" target="_blank">
                      <Typography variant="h6" component="p">
                        Whatsapp +54 11 65 19 92 87
                      </Typography>
                    </a>
                    <Typography variant="body1">
                      Envianos un mensaje. Podes reservar por este medio.
                    </Typography>              
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} lg={3} md={4} container justify="center" spacing={0} alignItems="center">
          <Box p={1}>
          <Paper className={classes.paper} elevation={3}>
            <Grid container justify="center" alignItems="center" direction="column" >
              <Grid item>
                <Box component="div">
                  <a href="https://www.instagram.com/ConexionBerlinJuegos" className={classes.link} >
                  <img src={instagram} className={classes.svgIcon} alt="instagram Icon" title="seguinos en instagram" />
                  </a>
                </Box>
                <Box component="div" m={1}>
                  <a href="https://www.instagram.com/ConexionBerlinJuegos" color="inherit" className={classes.link} rel="noreferrer" target="_blank">
                    <Typography variant="h6" component="p">
                      Instagram
                    </Typography>
                  </a>
                  <Typography variant="body1">
                    Mirá las imágenes que compartimos y escribinos.
                  </Typography>              
                </Box>
              </Grid>
            </Grid>
          </Paper>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} lg={3} md={4} container justify="center" spacing={0} alignItems="center">
          <Box p={1}>
          <Paper className={classes.paper} elevation={3}>
            <Grid container justify="center" alignItems="center" direction="column" >
              <Grid item>
                <Box component="div">
                  <a href="https://facebook.com/conexionBerlinJuegos" >
                    <img src={facebook} className={classes.svgIcon} alt="facebook Icon" />
                  </a>
                </Box>
                <Box component="div" m={1}>
                  <a href="https://facebook.com/conexionBerlinJuegos" color="inherit" title="visita nuestro facebook" className={classes.link} rel="noreferrer" target="_blank">
                    <Typography variant="h6" component="p">
                      Facebook
                    </Typography>
                  </a>
                  <Typography variant="body1">
                    Envianos un mensaje. Podes reservar por este medio.
                  </Typography>              
                </Box>
              </Grid>
            </Grid>
          </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} lg={3} md={4} container justify="center" spacing={0} alignItems="center">
          <Box p={1}>
          <Paper className={classes.paper} elevation={3}>
            <Grid container justify="center" alignItems="center" direction="column" >
              <Grid item>
                <Box component="div">
                  <a href="mailto:jugar@conexionberlin.com" className={classes.link} >
                    <img src={email} className={classes.svgIcon} alt="email Icon" title="envíanos un email" />
                  </a>
                </Box>
                <Box component="div" m={1}>
                  <a href="mailto:jugar@conexionberlin.com" color="inherit" className={classes.link} rel="noreferrer" target="_blank">
                    <Typography variant="h6" component="p">
                      Email
                    </Typography>
                  </a>
                  <Typography variant="body1">
                    Envianos un mensaje.
                  </Typography>              
                </Box>
              </Grid>
            </Grid>
          </Paper>
          </Box>

        </Grid>
      </Grid>
          
      <Grid container 
        direction="row"
        justify="center"
        alignItems="center"
        spacing={0}
        >
        <Grid item xs={12} lg={12} md={12} xl={12}>
          <Box elevation={3} className={classes.footer}>
            <Typography variant="body1">
            Conexión Berlin.
            </Typography>
            <Typography variant="body1">
            Córdoba 2786.
            </Typography>
            <Typography variant="body1">
            Olivos. Buenos Aires. 1636.
            </Typography>            
          </Box>
        </Grid>
      </Grid>
      
     
    </Fragment>
  )
};

export default Contact;
