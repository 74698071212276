import slugify from 'slugify';
import {useEffect, useState} from 'react';
export const COLORS = [
  'red', 
  'lightCoral', 
  'pink', 
  'hotPink', 
  'deepPink',
  'tomato',
  'orangeRed',
  'darkOrange',
  'gold',
  'khaki',
  'darkKhaki',
  'rebeccaPurple',
  'purple',
  'slateBlue',
  'indigo',
  'limeGree',
  'mediumSeaGreen',
  'darkGreen',
  'olive',
  'mediumAquamarine',
  'lightSeaGreen',
  'teal',
  'paleTurquoise',
  'turquoise',
  'darkTurquoise',
  'steelBlue',
  'skyBlue',
  'dodgerBlue',
  'royalBlue',
  'navy',
]

export const DEFAULT_LISTS = [
  'loved',
  'wantToPlay',
  'played'
]



export function mapUserGames(games, userGames) {
  const result = {}
  Object.entries(userGames).forEach(([list, entry]) => {
    result[list] = entry.map((gameId) => games.find(g => g.id === gameId))
  })
  return result;
}

export function parseLists(data, callback) {
  let lists = []
  return data.then(entries => {
    entries.forEach((doc) => {
      console.log('doc', doc)
      const list = {id: doc.id, ...doc.data()}
      lists.push(list)
    })
    callback(lists)
  })

}

export function parseListsGames(data, userLists, callback) {
  const userGames = {}
  console.log('data', data)
  Object.keys(userLists).forEach((listId) => {
    console.log('listId', listId)
    const userList = data.find((l) => l.id === listId)
    if (userList && userList.games) {
      userGames[userLists[listId].slug] = { ...userLists[listId], games: userList.games}
    }
  })
  callback(userGames)
  return userGames
}

export function parseGamesData(data, callback, userLists) {
  const extraLists = userLists.reduce((accum, ul) => {
    accum[ul.key] = []
    return accum
  }, {})
  let list = {
    loved: [],
    played: [],
    wantToPlay: [],
    wantToBuy: [],
    ...extraLists,
  }
  return data.then(games => {
    games.forEach((doc) => {
      const game = doc.data()
      if (game.loved === true) {
        list.loved.push(doc.id)
      }
      if (game.played === true) {
        list.played.push(doc.id)
      } 
      if (game.wantToPlay === true ) {
        list.wantToPlay.push(doc.id)
      } 
      if (game.wantToBuy === true) {
        list.wantToBuy.push(doc.id)
      }

    })
    callback(list)
  })
}

export function userGamesRef(firestoreLite, uid) {
  const docRef = firestoreLite.collection && firestoreLite.collection(firestoreLite.firestoreLite, 'users', uid, 'games');
  return docRef
}

const useUserGames = (firestoreLite, anonAuth) => {
  const [userGames, setUserGames] = useState()
  const [userLists, setUserLists] = useState()
  function reloadGames() {
    setUserGames(null)
  }
  function reloadLists() {
    setUserLists(null)
  }
  useEffect(() => {
    const loadLists = async () => {
      const docRef = firestoreLite.doc && firestoreLite.doc(firestoreLite.firestoreLite, 'users', anonAuth.user.uid);
      anonAuth.subscribeOnAuthStateChange(reloadLists)
      const userData = await firestoreLite.getDoc(docRef);
      const docData = await userData.data();
      return docData;
    }
    if (firestoreLite && firestoreLite.collection && anonAuth && anonAuth.user && !userLists) {
      loadLists().then((data) => {
        setUserLists(data.lists)  
      })
      // parseLists(data.lists, setUserLists)
    }
  }, [firestoreLite, anonAuth, userLists]);

  useEffect(() => {
    const loadGames = async () => {
      const colRef = firestoreLite.collection && firestoreLite.collection(firestoreLite.firestoreLite, '/lists')
      const queryLists = firestoreLite.query(colRef, firestoreLite.where('uid', '==', anonAuth.user.uid))
      anonAuth.subscribeOnAuthStateChange(reloadGames)
      const lists = []
      const listsSnap = (await firestoreLite.getDocs(queryLists)).docs
      listsSnap.forEach((doc) => {
        lists.push({id: doc.id, ...doc.data()})
      })
      return lists
    }
    if (firestoreLite && firestoreLite.firestoreLite && firestoreLite.collection && anonAuth && anonAuth.user && userLists && !userGames) {
      loadGames().then((data) => {
        parseListsGames(data, userLists, setUserGames)
  
      })
    }
  }, [firestoreLite, anonAuth, userGames, userLists]);

  // console.log('userGames', userGames)

  async function addGameToList(listId, gameId) {
    const userListRef = firestoreLite.doc(firestoreLite.firestoreLite, 'lists', listId)
    return await firestoreLite.setDoc(userListRef, { 
      games: firestoreLite.arrayUnion(...[
        {gameId, timestamp: firestoreLite.Timestamp.now()}
      ]),
      timestamp: firestoreLite.serverTimestamp()
    }, { 
      merge: true
    })
  }

  function listSlugFromId(listId) {
    const [slug, list] = Object.entries(userGames).find(([k,v]) => v.listId === listId)
    return list
  }

  function gameInList(games, gameId) {
    return (games || []).map((g) => g.gameId).includes(gameId)
  }


  async function addList(name, color) {
    // const extraListNames = COLORS
    const listsCol = firestoreLite.collection(firestoreLite.firestoreLite, '/lists')
    const newListRef = firestoreLite.doc(listsCol)
    const listId = newListRef.id
    const slug = slugify(name, { lower: true, strict: true })
    const newListDoc = {
      name,
      slug,
      sharedWith: [],
      games: [],
      uid: anonAuth.user.uid,
      userId: anonAuth.user.uid,
      createdAt: firestoreLite.serverTimestamp()
    }
    await firestoreLite.setDoc(newListRef, newListDoc)
    const userRef = firestoreLite.doc(firestoreLite.firestoreLite, 'users', anonAuth.user.uid)
    const userList = {
      lists: {
        [listId]: {
          list: newListRef,
          listId,
          name,
          slug,
          createdAt: firestoreLite.serverTimestamp()
        }
      }
    }

    await firestoreLite.setDoc(userRef, userList, {merge: true})
    setUserLists((prev) => {
      const next = {
        ...prev,
        ...userList.lists,
      }
      return next
    })
    return true
  }

  async function updateList(id, data) {
    const userListRef = firestoreLite.doc(firestoreLite.firestoreLite, 'users', anonAuth.user.uid, 'lists', id)
      await firestoreLite.deleteDoc()
    return await firestoreLite.updateDoc(userListRef, data)
  }
  async function removeList(id) {
    const listsCol = firestoreLite.doc(firestoreLite.firestoreLite, '/lists', id)
    const userRef = firestoreLite.doc(firestoreLite.firestoreLite, 'users', anonAuth.user.uid)
    await firestoreLite.deleteDoc(listsCol)
    const updatedLists = Object.entries(userLists).reduce((accum, [listId, list]) => {
      if (id === listId && !DEFAULT_LISTS.includes(list.slug)) return accum;
      accum[listId] = list
      return accum;
    }, {})

    const userList = {
      lists: updatedLists
    }
    await firestoreLite.setDoc(userRef, userList, {merge: true})
    setUserLists((prev) => {
      const next = {
        ...userList.lists,
      }
      return next
    })

  }
  async function getLists() {
    const userListRef = firestoreLite.doc(firestoreLite, 'users', anonAuth.user.uid, 'lists')
    const snap = await firestoreLite.collection(userListRef).getDocs()
    let records = []
    snap.forEach((doc) => {
      const entry = doc.data()
      records.push({uid: doc.id, ...entry})
    })
  }

  console.log('userLists', userLists)
  console.log('userGames', userGames)

  return {
    addList,
    addGameToList,
    colors: COLORS,
    getLists,
    reloadGames,
    removeList,
    setUserGames,
    setUserLists,
    updateList,
    user: anonAuth && anonAuth.user,
    userGames,
    userLists,
    gameInList,
    listSlugFromId,
    DEFAULT_LISTS,
  }
}

export default useUserGames;

