import {useEffect, useState} from 'react';

const useFirebaseFirestore = (fbase) => {
  const [firestore, setFirestore] = useState()  

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const loadFirestore = async() => {
        const { getFirestore } = await import('firebase/firestore');
        const firestore = getFirestore(fbase)
        setFirestore(firestore)  
      }
      if (fbase && !firestore) {
        loadFirestore()
      }
      } else {
        const loadFirestore = async() => {
          const { getFirestore, connectFirestoreEmulator } = await import('firebase/firestore');
          const firestore = getFirestore(fbase)
          connectFirestoreEmulator(firestore, 'localhost', 5500)
          // connectFirestoreEmulator(firestore, '192.168.100.35', 5500)
          // connectFirestoreEmulator(firestore)
          setFirestore(firestore)
        }
        if (fbase && !firestore) {
          loadFirestore()
        }
    
    }
  }, [fbase, firestore])
  return firestore
}

export default useFirebaseFirestore;

