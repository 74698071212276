import React, {useEffect, useState } from 'react'
import CasinoTwoToneIcon from '@material-ui/icons/CasinoTwoTone';
import CasinoOutlinedIcon from '@material-ui/icons/CasinoOutlined';
import IconButton from '@material-ui/core/IconButton';

export const PlayedIcon = props => {
  const { fontSize = 'default' } = props;
  return (
    <CasinoTwoToneIcon fontSize={fontSize} style={{ color: 'rgb(255, 192, 0)' }} />
  )
}

export const Played = props => {
  const { addFavorite, played } = props;
  return (
      <IconButton aria-label="favorito" onClick={addFavorite}>
        {played && <CasinoTwoToneIcon style={{ color: 'rgb(255, 192, 0)' }} />}
        {!played && <CasinoOutlinedIcon />}
      </IconButton>
  )
}

const usePlayedGame = (firestore, user, userGames, gameId, isMounted = true) => {
  const [playedState, setPlayedState] = useState()
  const addPlayed = () => async (ev) => {
    ev.stopPropagation()
    ev.preventDefault()
    ev.persist()
    if (isMounted && firestore && firestore.firestoreLite) {
      await userGames.addGameToList(userGames.userGames.played.listId, gameId)
      setPlayedState(!playedState)
    }
  }
  useEffect(() => {
    if (isMounted && playedState === undefined && userGames && userGames.userGames  && userGames.userGames.played) {
      const played = userGames.gameInList(userGames.userGames.played.games, gameId)
      setPlayedState(played)
    }
  },[userGames, playedState, gameId, isMounted])
  
  return {
    playedState,
    setPlayedState,
    userGames,
    addPlayed,
  }
}

export default usePlayedGame;


