import {useEffect, useState} from 'react';

// eslint-disable-next-line no-undef
const isProd = (process.env.NODE_ENV === 'production')

const useFirebaseFirestoreLite = (fbase) => {
  const [firestoreLite, setFirestoreLite] = useState()  

  useEffect(() => {
    const loadFirestore = async() => {
      if (isProd) {
        const { 
          getFirestore,
          getDoc,
          updateDoc,
          doc,
          setDoc,
          addDoc,
          getDocs,
          deleteDoc,
          where,
          collection,
          serverTimestamp,
          query,
          queryEqual,
          arrayUnion,
          Timestamp,
        } = await import('@firebase/firestore/lite');
        const firestore = getFirestore(fbase)
        
        setFirestoreLite({
          firestoreLite: firestore,
          getDoc,
          getDocs,
          addDoc,
          updateDoc,
          deleteDoc,
          doc,
          query,
          queryEqual,
          where,
          setDoc,
          collection,
          serverTimestamp,
          arrayUnion,
          Timestamp,
        })
      } else {
        const { 
          getFirestore,
          getDoc,
          getDocs,
          where,
          updateDoc,
          doc,
          setDoc,
          addDoc,
          deleteDoc,
          collection,
          query,
          queryEqual,
          serverTimestamp,
          arrayUnion,
          connectFirestoreEmulator,
          Timestamp,
        } = await import('@firebase/firestore/lite');
        const firestore = getFirestore(fbase)
        try {
          connectFirestoreEmulator(firestore, 'localhost', 5500)
          // connectFirestoreEmulator(firestore, '192.168.100.35', 5500)
        } catch (err) {
          console.log('already connected', err)
        }
        setFirestoreLite({
          getDocs,
          firestoreLite: firestore,
          getDoc,
          addDoc,
          updateDoc,
          deleteDoc,
          doc,
          setDoc,
          where,
          query,
          queryEqual,
          collection,
          arrayUnion,
          serverTimestamp,
          Timestamp,
        })

      }

    }
    if (fbase && !firestoreLite) {
      loadFirestore()
    }
  }, [fbase, firestoreLite])
  return firestoreLite
}

export default useFirebaseFirestoreLite;

