import {useEffect, useState} from 'react';

const useFirebaseFunctions = (fbase) => {
  const [functions, setFunctions] = useState()  

  useEffect(() => {
    const loadFunc = async() => {
      // eslint-disable-next-line no-undef
      if (process.env.NODE_ENV === 'production') {
        const { getFunctions, httpsCallable } = await import('firebase/functions');
        const func = getFunctions(fbase)
        setFunctions({
          functions: func,
          httpsCallable,
        }) 
      } else {
        const { getFunctions, httpsCallable, connectFunctionsEmulator } = await import('firebase/functions');
        const func = getFunctions(fbase)
        try {
          connectFunctionsEmulator(func, "localhost", 5001);
          // connectFunctionsEmulator(func, "192.168.100.35", 5001);
        } catch (err) {
          console.log('already connected', err)
        }
        setFunctions({
          functions: func,
          httpsCallable,
        }) 
      }
    }
    if (fbase && !functions) {
      loadFunc()
    }
  }, [fbase, functions])
  return functions
}

export default useFirebaseFunctions;

