import React from 'react';

const SearchContext = React.createContext();

export const withSearch = Component => props => {
  return (
    <SearchContext.Consumer>
      {search => <Component {...props} search={search} />}
    </SearchContext.Consumer>
  );  
}

export default SearchContext;
