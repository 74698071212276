import { useEffect, useState } from 'react';
import getJson from '../lib/network/getJson';

const useEvents = (props={}) => {
  const [list, setList] = useState()
  const { filename = 'events.json' } = props;
  const path = (process.env.NODE_ENV === 'production') ? `https://storage.googleapis.com/persistence/` : `/data/persistence/`
  const fullPath = `${path}${filename}`
  useEffect(() => {
    const fetchHandler = () => {
      return getJson(fullPath).then((json) => {
        console.log('json', json)
        setList(json);
      });
    };
    if (!list) {
      fetchHandler();
    }
  }, [fullPath, list, setList]);
  const findById = (id) => list.find((ev) => ev.id === id)
  
  return {list, setList, path, fullPath, findById}
}

export default useEvents;