import * as React from "react"

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 512 512" style={{width: (props.width || 60), height: (props.height || 60), filter: 'drop-shadow(4px 4px 4px #666)'}} {...props}>
      <path
        d="M104 296a8 8 0 01-8-8v-33.373c0-12.455-4.85-24.164-13.656-32.97a8 8 0 1111.312-11.314C105.485 222.171 112 237.898 112 254.627V288a8 8 0 01-8 8z"
        fill="#faa037"
      />
      <path
        d="M64 176H48v-24a8 8 0 0116 0zM56 224a8 8 0 01-8-8v-24h16v24a8 8 0 01-8 8zM64 192v-16h24a8 8 0 010 16zM16 184a8 8 0 018-8h24v16H24a8 8 0 01-8-8z"
        fill="#ffd241"
      />
      <path
        d="M408 296a8 8 0 01-8-8v-33.373c0-16.729 6.515-32.456 18.344-44.284a8 8 0 0111.312 11.314C420.85 230.463 416 242.172 416 254.627V288a8 8 0 01-8 8z"
        fill="#faa037"
      />
      <path
        d="M296 280h-80a8 8 0 01-8-8v-16h96v16a8 8 0 01-8 8z"
        fill="#555a6e"
      />
      <path
        d="M208 256h96v8h-96zM304 264h-48v16h40a8 8 0 008-8z"
        fill="#463c4b"
      />
      <path
        d="M416 496H96c-17.673 0-32-14.327-32-32V288h384v176c0 17.673-14.327 32-32 32z"
        fill="#c8733c"
      />
      <path
        d="M224 496H96c-17.673 0-32-14.327-32-32V288h192v176c0 17.673-14.327 32-32 32z"
        fill="#f0915a"
      />
      <path
        d="M256 288c-17.673 0-32 14.327-32 32v32a8 8 0 01-8 8H40a8 8 0 01-8-8v-32c0-17.673 14.327-32 32-32z"
        fill="#fab991"
      />
      <path
        d="M256 288c17.673 0 32 14.327 32 32v32a8 8 0 008 8h176a8 8 0 008-8v-32c0-17.673-14.327-32-32-32z"
        fill="#f0915a"
      />
      <path
        d="M304 256h-48V16c10.245 0 20.07 4.07 27.314 11.314A125.254 125.254 0 01320 115.882V240c0 8.837-7.163 16-16 16z"
        fill="#a5c3dc"
      />
      <path
        d="M208 256h48V16a38.628 38.628 0 00-27.314 11.314A125.254 125.254 0 00192 115.882V240c0 8.837 7.163 16 16 16z"
        fill="#d7e6f0"
      />
      <circle cx={256} cy={120} fill="#ff6e91" r={40} />
      <path
        d="M296 120c0-22.091-17.909-40-40-40v80c22.091 0 40-17.909 40-40z"
        fill="#e64178"
      />
      <circle cx={256} cy={120} fill="#00d2d2" r={24} />
      <path
        d="M256 176a8 8 0 00-8 8v80a8 8 0 0016 0v-80a8 8 0 00-8-8z"
        fill="#ff6e91"
      />
      <path
        d="M320 240l28.686 28.686a11.313 11.313 0 008 3.314c6.248 0 11.314-5.065 11.314-11.314v-26.745c0-6.365-2.529-12.47-7.029-16.971L320 176z"
        fill="#e64178"
      />
      <path
        d="M192 240l-28.686 28.686a11.313 11.313 0 01-8 3.314c-6.248 0-11.314-5.065-11.314-11.314v-26.745c0-6.365 2.529-12.47 7.029-16.971L192 176zM256 16v32h-44.01a124.89 124.89 0 0116.7-20.69c3.62-3.62 7.89-6.45 12.53-8.37S250.88 16 256 16z"
        fill="#ff6e91"
      />
      <path
        d="M300.01 48H256V16c5.12 0 10.14 1.02 14.78 2.94s8.91 4.75 12.53 8.37A124.89 124.89 0 01300.01 48z"
        fill="#e64178"
      />
      <path d="M216 280h80v8h-80z" fill="#00d2d2" />
      <path
        d="M448 176h16v-24a8 8 0 00-16 0zM456 224a8 8 0 008-8v-24h-16v24a8 8 0 008 8zM448 192v-16h-24a8 8 0 000 16zM496 184a8 8 0 00-8-8h-24v16h24a8 8 0 008-8zM360 48h16V32a8 8 0 00-16 0zM368 88a8 8 0 008-8V64h-16v16a8 8 0 008 8zM360 64V48h-16a8 8 0 000 16zM400 56a8 8 0 00-8-8h-16v16h16a8 8 0 008-8z"
        fill="#ffd241"
      />
      <path
        d="M368 168a8 8 0 01-8-8v-48a8 8 0 0116 0v48a8 8 0 01-8 8zM368 200a8 8 0 01-8-8v-8a8 8 0 0116 0v8a8 8 0 01-8 8z"
        fill="#faa037"
      />
      <path
        d="M136 48h16V32a8 8 0 00-16 0zM144 88a8 8 0 008-8V64h-16v16a8 8 0 008 8zM136 64V48h-16a8 8 0 000 16zM176 56a8 8 0 00-8-8h-16v16h16a8 8 0 008-8z"
        fill="#ffd241"
      />
      <path
        d="M144 168a8 8 0 01-8-8v-48a8 8 0 0116 0v48a8 8 0 01-8 8zM144 200a8 8 0 01-8-8v-8a8 8 0 0116 0v8a8 8 0 01-8 8z"
        fill="#faa037"
      />
      <circle cx={448} cy={96} fill="#faa037" r={16} />
      <circle cx={64} cy={96} fill="#faa037" r={16} />
    </svg>
  )
}

export default SvgComponent
